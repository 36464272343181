import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import {
  A11yUtilsModule,
  AokDialogResult,
  AokMenuButtonEntry,
  AokSvgIconComponent,
  AokToastService,
  AokUserClient,
  ContextState,
  CurrentUserState,
  DialogOverlay,
  getParsableQueryParams,
  isDoctor,
  isKvnFullDoctor,
  TableTypeRow,
} from '@aok/common';
import {
  AokNotificationBannerModule,
  AokTableModule,
  DialogManagerService,
  DirectRegistration2faDialog,
  KvnState,
} from '@aok/components';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProfileDataService } from '../../../services/profile-data.service';
import { OPT_CHANGE_MAIL, OPT_CHANGE_PW, OPT_CHANGE_SMS_2FA_DATA } from './profile-data.config';

@Component({
  selector: 'aok-cockpit-profile-data',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './profile-data.component.html',
  styleUrls: ['./profile-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AokNotificationBannerModule, AokTableModule, A11yUtilsModule, AokSvgIconComponent],
})
export class ProfileDataComponent implements OnInit {
  public isDoctor = isDoctor;
  public isKvn: boolean;
  public isKvnOrigin: boolean;
  public allowDirectRegistration: boolean;
  protected optionEntries: AokMenuButtonEntry[];
  private destroyRef = inject(DestroyRef);

  public get hasBothRoles(): boolean {
    return isKvnFullDoctor(this.currentUser.snapshot.userType);
  }

  constructor(
    protected currentUser: CurrentUserState,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: DialogOverlay,
    private userClient: AokUserClient,
    private toastService: AokToastService,
    private dialogService: DialogManagerService,
    private contextState: ContextState,
    private kvnState: KvnState,
    private profileDataService: ProfileDataService
  ) {
    this.allowDirectRegistration = this.route.snapshot.data['allowDirectRegistration'];
    this.optionEntries = this.profileDataService.getOptionEntries();
    this.handleChangedPassword();
  }

  ngOnInit(): void {
    this.isKvn = this.kvnState.isKvn();
    this.isKvnOrigin = this.kvnState.isKvnOrigin();
  }

  public onPracticeDataOptionSelect(): void {
    // option entry is always 'change data'
    this.profileDataService.openEditOrganizationEmail();
  }

  public onAccessDataOptionSelect(opt: AokMenuButtonEntry): void {
    switch (opt.id) {
      case OPT_CHANGE_PW.id:
        this.profileDataService.redirectToPasswordPage();
        break;
      case OPT_CHANGE_MAIL.id:
        this.openChangeEmailDialog();
        break;
      case OPT_CHANGE_SMS_2FA_DATA.id:
        this.profileDataService.openChange2faData();
        break;
    }
  }

  public deleteProfile(): void {
    this.currentUser.isLastDoctorAtBSNRLocation(this.contextState.bsnr).subscribe((isLastDoctorAtBSNRLocation) => {
      this.profileDataService.openProfileDeleteDialog(isLastDoctorAtBSNRLocation);
    });
  }

  public getPersonalDataKeyValuePair(): Observable<TableTypeRow[]> {
    return this.currentUser.asObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      filter((user) => !!user),
      map((user) => this.profileDataService.createPersonalDataKeyValuePair(user))
    );
  }

  public getAccessDataKeyValuePair(): Observable<TableTypeRow[]> {
    return this.currentUser.asObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      filter((user) => !!user),
      map((user) => this.profileDataService.createAccessDataKeyValuePair(user))
    );
  }

  public open2FASelection(): void {
    this.userClient.isCardOrderingEnabled().subscribe((isCardOrderingEnabled) => {
      this.dialog
        .create(DirectRegistration2faDialog, {
          props: {
            isCardOrderingEnabled: isCardOrderingEnabled,
          },
          closable: true,
        })
        .subscribe((result) => {
          if (result !== AokDialogResult.CONFIRM) {
            return;
          }

          const { ...preservedQueryParams } = this.route.snapshot.queryParams;
          const urlTreeString = this.router
            .createUrlTree(['.'], {
              relativeTo: this.route,
              queryParams: preservedQueryParams,
            })
            .toString();

          window.location.assign(`${window.location.origin}/${urlTreeString}`);
        });
    });
  }

  private handleChangedPassword(): void {
    this.route.fragment.pipe(takeUntilDestroyed()).subscribe((fragment) => {
      const asParams = getParsableQueryParams(fragment);
      if (asParams['kc_action_status'] === 'success') {
        this.toastService.createSuccessToast(
          'Ihre Daten wurden gespeichert.',
          'Ab sofort können Sie sich mit Ihrem neuen Passwort anmelden.'
        );
      }
    });
  }

  private openChangeEmailDialog(): void {
    this.dialogService.openChangeUserMailDialog(this.currentUser.snapshot).subscribe((success) => {
      if (success) {
        this.router.navigate([]).then();
      }
    });
  }
}
