import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AddressData,
  AokDialogResult,
  AokDmpCaseManagementClient,
  AokFullNamePipe,
  AokOrg,
  AokPage,
  AokToastService,
  AokUser,
  AokUserClient,
  ContextState,
  CurrentUserState,
  DialogBase,
  DialogRef,
  DMPCase,
  DropdownSchema,
  FormErrorDetectorRef,
  isString,
  KnownUserType,
  NotificationBannerLink,
  phoneNumber,
  sanitizePhoneNumber,
  scrollToElement,
  scrollToTop,
} from '@aok/common';
import { EMPTY, ReplaySubject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ContactPersonDropdownSchema, DropdownMode } from '../../../schema';
import { dmpCaseFeedbackCategoryOptions } from './feedback-dmp-case-dialog.config';

@Component({
  selector: 'aok-feedback-dmp-case-dialog',
  templateUrl: './feedback-dmp-case-dialog.component.html',
  styleUrls: ['./feedback-dmp-case-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackDmpCaseDialog implements OnInit, OnDestroy, DialogBase<AokDialogResult> {
  @Input() public dmpCase: DMPCase;

  public errorIds: NotificationBannerLink[] = [];
  public bannerLabel = 'Bitte überprüfen Sie Ihre Eingabe.';
  public bannerError = null;

  public dropdownMode = DropdownMode;
  public isMessageRequired = false;

  public contactPersonOptions: ContactPersonDropdownSchema[];
  public practiceOptions: DropdownSchema[] = [];
  public contextOrganizations: Partial<AokOrg>[];
  public dmpCaseFeedbackCategoryOptions = dmpCaseFeedbackCategoryOptions;

  public feedbackForm = new UntypedFormGroup({
    dmpCaseFeedbackCategory: new UntypedFormControl(null, Validators.required),
    message: new UntypedFormControl(null, Validators.maxLength(500)),
    selectedUserId: new UntypedFormControl(null, Validators.required),
    phoneCheckbox: new UntypedFormControl(false),
    phoneNumber: new UntypedFormControl({ value: null, disabled: true }, phoneNumber),
    letterCheckbox: new UntypedFormControl(false),
    selectedPracticeId: new UntypedFormControl({ value: null, disabled: true }),
  });

  public label = {
    dmpCaseFeedbackCategory: 'Angelegenheit',
    message: 'Ihre Nachricht',
    selectedUserId: 'Gesprächspartner wählen oder eingeben',
    phoneCheckbox: 'Kontaktaufnahme',
    phoneNumber: 'Telefon',
    letterCheckbox: '',
    selectedPracticeId: 'An folgende Praxis senden',
  };

  private ngDestroys = new ReplaySubject<void>(1);

  constructor(
    public readonly dialogRef: DialogRef<AokDialogResult>,
    private router: Router,
    protected errorDetectorRef: FormErrorDetectorRef,
    public contextState: ContextState,
    private userClient: AokUserClient,
    private fullNamePipe: AokFullNamePipe,
    private toastService: AokToastService,
    readonly currentUser: CurrentUserState,
    private client: AokDmpCaseManagementClient
  ) {}

  ngOnInit(): void {
    this.subscribeToCategoryFormFieldChanges();
    this.subscribeToPracticeCheckboxChanges();
    this.subscribeToPhoneCheckboxChanges();
    this.subscribeToSelectedUserIdChanges();

    this.setPracticeOptions();
    this.loadNameOfAllDoctorsAndPMs();
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  public submitDialog(): void {
    if (this.feedbackForm.invalid) {
      this.handleErrors();
    } else {
      this.errorIds = [];

      const feedbackFormValue = this.feedbackForm.value;
      if (typeof feedbackFormValue.selectedUserId !== 'number') {
        feedbackFormValue.selectedUserName = feedbackFormValue.selectedUserId;
        feedbackFormValue.selectedUserId = null;
      }

      const feedbackRequest = {
        lanrContext: this.contextState.practitionerLanr9,
        bsnrContext: this.contextState.bsnr,
        dmpCaseId: this.dmpCase.id,
        ...feedbackFormValue,
        phoneNumber: sanitizePhoneNumber(feedbackFormValue.phoneNumber),
      };

      this.client
        .sendDmpCaseFeedback(feedbackRequest)
        .pipe(
          takeUntil(this.ngDestroys),
          catchError((error) => {
            this.feedbackErrorHandling(error);
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.toastService.createSuccessToast('Nachricht erfolgreich übermittelt', 'Vielen Dank für Ihre Nachricht.');
          this.dialogRef.dispose(AokDialogResult.CONFIRM);
        });
    }
  }

  public openPrivacyPage(): void {
    this.router.navigate(['datenschutz']);
    this.dialogRef.dispose();
  }

  public getSelectedOrgAddress(): AddressData {
    return this.contextState.contextSelectorOptions.find(({ organizationData }) => {
      return organizationData.id === this.feedbackForm.get('selectedPracticeId').value;
    })?.organizationData?.address;
  }

  private feedbackErrorHandling(error: HttpErrorResponse): void {
    let toastHeadline = 'Nachrichtenversand fehlgeschlagen';
    let toastLabel = 'Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.';
    if (error.status === 403) {
      toastHeadline = 'Vorgang fehlgeschlagen';
      toastLabel = 'Sie haben keinen Zugriff mehr auf diesen Datensatz.';
    }
    this.toastService.createErrorToast(toastHeadline, toastLabel);
  }

  private setPracticeOptions(): void {
    this.contextOrganizations = this.contextState.contextSelectorOptions
      .map(({ organizationData }) => organizationData)
      .filter(({ id }, index, array) => {
        return index === array.findIndex((org) => org.id === id);
      });

    this.practiceOptions = this.contextOrganizations.map((option) => {
      return {
        value: option?.id,
        label: `BSNR ${option?.bsnr} | Praxis ${option?.name} `,
      };
    });

    this.feedbackForm.get('selectedPracticeId').setValue(this.contextState.org.id);
  }

  private subscribeToCategoryFormFieldChanges(): void {
    this.feedbackForm
      .get('dmpCaseFeedbackCategory')
      .valueChanges.pipe(takeUntil(this.ngDestroys))
      .subscribe((value) => {
        const messageControl = this.feedbackForm.get('message');

        if (['OTHER_REASON', 'OTHER'].includes(value)) {
          messageControl.setValidators(Validators.required);
          messageControl.updateValueAndValidity();
          this.isMessageRequired = true;
          return;
        }

        messageControl.removeValidators(Validators.required);
        messageControl.updateValueAndValidity();
        this.isMessageRequired = false;
      });
  }

  private subscribeToPhoneCheckboxChanges(): void {
    this.feedbackForm
      .get('phoneCheckbox')
      .valueChanges.pipe(takeUntil(this.ngDestroys))
      .subscribe((value) => {
        const phoneNumber = this.feedbackForm.get('phoneNumber');

        if (value) {
          phoneNumber.addValidators(Validators.required);
          phoneNumber.updateValueAndValidity();
          phoneNumber.enable();
        } else {
          phoneNumber.removeValidators(Validators.required);
          phoneNumber.updateValueAndValidity();
          phoneNumber.disable();
        }
      });
  }

  private subscribeToPracticeCheckboxChanges(): void {
    this.feedbackForm
      .get('letterCheckbox')
      .valueChanges.pipe(takeUntil(this.ngDestroys))
      .subscribe((value) => {
        const selectedPracticeId = this.feedbackForm.get('selectedPracticeId');
        value ? selectedPracticeId.enable() : selectedPracticeId.disable();
      });
  }

  private loadNameOfAllDoctorsAndPMs(): void {
    this.contextState
      .getOrg$()
      .pipe(
        takeUntil(this.ngDestroys),
        filter((org) => !!org),
        switchMap((org) =>
          this.userClient.list({
            bsnr: org.bsnr,
            size: 1000,
            userType: [
              KnownUserType.Doctor,
              KnownUserType.Kvn_Doctor,
              KnownUserType.Full_Kvn_Doctor,
              KnownUserType.Assistant,
            ],
          })
        ),
        map((response: AokPage<AokUser>) => response?._embedded?.items),
        map((users: AokUser[]) => {
          return users.map((user) => ({
            value: user.id,
            label: `${this.fullNamePipe.transform(user, 'TT FF LL', user.practitionerResource)}`.trim(),
            mobilePhone: user.mobilePhone,
          }));
        })
      )
      .subscribe((contactPersonOptions: ContactPersonDropdownSchema[]) => {
        this.contactPersonOptions = contactPersonOptions;

        const currentUser = this.contactPersonOptions.find(
          (selectedUserId) => selectedUserId.value === this.contextState.practitioner.id
        );

        this.feedbackForm.get('selectedUserId').setValue(currentUser?.value);
      });
  }

  private subscribeToSelectedUserIdChanges(): void {
    this.feedbackForm.get('selectedUserId').valueChanges.subscribe((value) => {
      const currentUser = this.contactPersonOptions.find((selectedUserId) => selectedUserId.value === value);
      this.feedbackForm.get('phoneNumber').setValue(currentUser?.mobilePhone ?? null);
    });
  }

  private handleErrors(): void {
    scrollToTop();

    const controls = Object.keys(this.feedbackForm.controls).map((key) => {
      this.feedbackForm.get(key).updateValueAndValidity();
      return key;
    });

    this.errorIds = controls
      .filter((key) => this.feedbackForm.get(key).invalid)
      .map((key) => {
        let label: string;
        if (isString(this.label?.[key])) label = this.label?.[key] as string;
        else label = this.label[key].alternative as string;
        return {
          label,
          clickHandler: () => scrollToElement(key),
        } as NotificationBannerLink;
      }, this.label);
  }
}
