import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import {
  AokLoadingStateService,
  AokMenuButtonEntry,
  AokOrg,
  AokRegistrationToOrganisation,
  AokUserRegistrationStatus,
  CurrentUserState,
  DialogOverlay,
  isAssistant,
  isDoctor,
  scrollToFragmentWithOffset,
  TableTypeRow,
} from '@aok/common';
import {
  AokTableModule,
  DialogManagerService,
  KvnPracticeInstructionsAddModeDoctorsDialog,
  KvnState,
  PracticeDataService,
  ProfileService,
  TableStatus,
} from '@aok/components';
import { first, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface JoinBsnrRequest {
  id: number;
  name: string;
  bsnr: string;
  value: TableTypeRow[];
  status?: TableStatus;
  options: AokMenuButtonEntry[];
  request?: AokRegistrationToOrganisation;
}

@Component({
  selector: 'aok-cockpit-practice-data',
  styleUrls: ['./practice-data.component.scss'],
  templateUrl: './practice-data.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AokTableModule],
})
export class PracticeDataComponent implements OnInit {
  public selectedPracticeData: TableTypeRow[] = [];
  public unselectedPracticeData: { org: AokOrg; value: TableTypeRow[] }[] = [];
  public requestJoinBSNRData: JoinBsnrRequest[] = [];

  public isAssistant: boolean;
  public selectedOrg: AokOrg;

  public displayedOptions: AokMenuButtonEntry[];

  private doctorOptions: AokMenuButtonEntry[] = [
    {
      title: 'Ändern der Praxisdaten',
      action: (orgId: number) => this.practiceDataService.sendRequestForUpdateOrg(orgId),
    },
  ];
  private mfaOptions: AokMenuButtonEntry[] = [
    {
      title: 'Praxiszugehörigkeit löschen',
      action: (bsnr: string) => this.practiceDataService.openDialogToDeletePracticeRelation(bsnr),
    },
  ];
  private doctorRequestsOptions: AokMenuButtonEntry[] = [
    {
      title: 'Antrag bearbeiten',
      action: (org?: AokRegistrationToOrganisation) => this.openAddPracticeDialog(org),
    },
  ];
  private mfaRequestsOptions: AokMenuButtonEntry[] = [
    {
      title: 'Anfrage löschen',
      action: (orgId: number) => this.practiceDataService.openDialogToDeleteMFAJoinBSNRRequest(orgId),
    },
  ];

  private alreadyJoined: string[] = []; // Array with BSNRs of already joined Practices
  private alreadyRequested: string[] = []; // Array with BSNRs of already requested Practices

  get hasAdditionalBSNRs(): Observable<boolean> {
    return this.currentUser.asObservable().pipe(map((user) => user.practitionerResource?.pendingSamlBsnrs?.length > 0));
  }

  constructor(
    private currentUser: CurrentUserState,
    private dialogService: DialogManagerService,
    private practiceDataService: PracticeDataService,
    private profileService: ProfileService,
    private kvnState: KvnState,
    private dialog: DialogOverlay,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private loadingStateService: AokLoadingStateService
  ) {
    this.isAssistant = isAssistant(this.currentUser.snapshot);
    this.setupData();
    this.scrollToElement();
  }

  ngOnInit(): void {}

  public openAddPracticeDialog(org?: AokRegistrationToOrganisation): void {
    if (this.kvnState.isKvnPracticeTeam()) {
      this.dialog.create(KvnPracticeInstructionsAddModeDoctorsDialog);
      return;
    }

    if (this.isAssistant) {
      this.dialogService
        .openJoinRequestToPracticeMfaDialog(this.profileService.getAllOrgs())
        .subscribe((successful: boolean) => {
          if (successful) {
            this.profileService.reloadProfileOrganisations();
          }
        });
    } else {
      this.dialogService
        .openJoinRequestToPracticeDoctorDialog(org, this.alreadyJoined, this.alreadyRequested)
        .subscribe((successful: boolean) => {
          if (successful) {
            this.profileService.reloadProfileOrganisations();
          }
        });
    }
  }

  public addPendingBSNRs(): void {
    this.practiceDataService.addPendingBsnrs();
  }

  protected joinBsnrRequestsTrackBy(index: number, bsnrRequest: JoinBsnrRequest): number {
    return bsnrRequest.id;
  }

  protected unselectedOrganizationsTrackBy(index: number, orgData: { org: AokOrg; value: TableTypeRow[] }): number {
    return orgData.org.id;
  }

  private setOptions(): void {
    if (this.isAssistant) {
      if (this.unselectedPracticeData?.length > 0) {
        this.displayedOptions = this.mfaOptions;
      } else {
        this.displayedOptions = [];
      }
    } else {
      this.displayedOptions = this.doctorOptions;
    }
    this.cd.markForCheck();
  }

  private setupData(): void {
    this.profileService.organisations
      .asObservable()
      .pipe(takeUntilDestroyed())
      .subscribe(({ selected, unselected, bsnrRequests }) => {
        this.alreadyJoined = selected?.org?.bsnr ? [selected.org.bsnr] : [];
        this.alreadyJoined.push(...(unselected?.map(({ org }) => org.bsnr) || []));
        this.alreadyRequested = bsnrRequests?.map((request) => request.organizationData.bsnr) || [];
        if (selected?.org) {
          this.selectedOrg = selected.org;
          this.selectedPracticeData = this.practiceDataService.getTableData(selected.org);
        }
        this.unselectedPracticeData = unselected.reduce((accu, organizationData) => {
          accu.push({
            org: organizationData.org,
            value: this.practiceDataService.getTableData(organizationData.org),
          });
          return accu;
        }, []);
        if (bsnrRequests) {
          if (this.isAssistant) {
            this.requestJoinBSNRData = bsnrRequests.map((request) => ({
              id: request.id,
              name: 'Praxis',
              bsnr: request.organizationData.bsnr,
              value: [{ label: 'Betriebsstättennummer', value: request.organizationData.bsnr }],
              options: this.mfaRequestsOptions,
            }));
          } else if (isDoctor(this.currentUser.snapshot)) {
            this.requestJoinBSNRData = bsnrRequests.map((request) => ({
              id: request.id,
              name: request.organizationData.organizationName,
              bsnr: request.organizationData.bsnr,
              value: this.practiceDataService.getTableData(request),
              status: this.practiceDataService.getStatus(request.status),
              options: request.status === AokUserRegistrationStatus.Rejected ? this.doctorRequestsOptions : null,
              request,
            }));
          }
        }
        this.cd.markForCheck();

        this.setOptions();
      });
  }

  private scrollToElement(): void {
    this.loadingStateService.isLoading$
      .pipe(
        filter((loading) => !loading),
        first()
      )
      .subscribe(() => {
        scrollToFragmentWithOffset(this.route.snapshot.fragment);
      });
  }
}
