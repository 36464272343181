import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  A11yUtilsModule,
  AokRegistrationOrgData,
  AokToastService,
  AokUserClient,
  createSimplePracticeForm,
  DialogBase,
  DialogRef,
} from '@aok/common';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AokNotificationBannerModule } from '../../notification-banner/notification-banner.module';
import { PracticeFormComponent } from '../../practice-form/practice-form.component';
import { AokStepComponent } from '../../stepper/step.component';
import { AokStepperComponent, StepChangeEvent, StepperNavigationMode } from '../../stepper/stepper.component';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';

@Component({
  selector: 'aok-cockpit-add-practices-dialog',
  templateUrl: './add-practices-dialog.component.html',
  styleUrls: ['./add-practices-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    AokDialogLayoutModule,
    A11yModule,
    A11yUtilsModule,
    AokStepperComponent,
    PracticeFormComponent,
    AokNotificationBannerModule,
    ReactiveFormsModule,
    AokStepComponent,
  ],
})
export class AddPracticesDialog implements OnInit, AfterViewInit, DialogBase<boolean> {
  @Input() organizations?: Partial<AokRegistrationOrgData>[];
  @Input() keycloakId?: string;
  @ViewChild(AokStepperComponent) stepper: AokStepperComponent;

  public formArray: FormArray<FormGroup>;
  public readonly NAV_MODE = StepperNavigationMode;

  constructor(
    public readonly dialogRef: DialogRef<boolean>,
    private cd: ChangeDetectorRef,
    private client: AokUserClient,
    private toastService: AokToastService
  ) {}

  ngAfterViewInit(): void {
    this.stepper.stepChange.subscribe((event: StepChangeEvent) => {
      this.stepper.activateStep(event.newStep);
      this.cd.markForCheck();
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  public submit(lastStep: AokStepComponent): void {
    if (lastStep.validate && !lastStep.validate()) {
      return;
    }

    const orgs = this.formArray.controls.map((control) => {
      return this.formToOrgData(control);
    });

    this.client
      .registerKvnAdditionalBsnrs(this.keycloakId, orgs)
      .pipe(
        catchError(() => {
          this.toastService.createSupportErrorToast(
            'Technischer Fehler',
            'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
          );

          return EMPTY;
        })
      )
      .subscribe(() => {
        this.toastService.createSuccessToast(
          'Neue Betriebsstätte verknüpft',
          'Bei Ihrem nächsten Besuch steht Ihnen die übermittelte Betriebsstätte zur Verfügung.'
        );
        this.dialogRef.dispose(true);
      });
  }

  private createForm(): void {
    const controls = [];
    this.organizations.forEach((org) => {
      controls.push(createSimplePracticeForm(org));
    });
    this.formArray = new FormArray(controls);
  }

  private formToOrgData(formGroup: FormGroup): AokRegistrationOrgData {
    const { bsnr, organizationType, organizationName, street, streetNumber, location, zipCode } =
      formGroup.getRawValue();
    return {
      bsnr: bsnr,
      organizationType,
      organizationName,
      address: {
        streetName: street,
        streetNumber,
        zipCode: zipCode,
        location,
      },
    };
  }
}
