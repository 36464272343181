import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[aokTrim]', standalone: true })
export class TrimControlDirective implements OnInit, OnDestroy {
  constructor(private el: ElementRef, private ngControl: NgControl) {}

  public ngOnInit(): void {
    this.el.nativeElement.addEventListener('blur', this.onBlur.bind(this));
  }

  public ngOnDestroy(): void {
    this.el.nativeElement.removeEventListener('blur', this.onBlur);
  }

  private onBlur(): void {
    const value = this.ngControl.control.value;
    const trimmedValue = value?.trim();
    if (value !== trimmedValue) {
      this.ngControl.control.setValue(trimmedValue, {
        emitEvent: false,
      });
    }
  }
}
