import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AokFocusFirstChildDirective,
  AokKeycloakTokenParsed,
  AokOrgClient,
  AokSpecializationState,
  AokToastService,
  AokUserClient,
  AokUserRegistration,
  AokUserRegistrationClient,
  AuthOption,
  createRegistrationRequestForm,
  DialogBase,
  DialogRef,
  extractAokRegistrationUserData,
  FormErrorDetectorRef,
  KnownUserType,
  resetErrors,
  sanitizePhoneNumber,
} from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { secondFactorAuthFormLabels, secondFactorAuthHint } from '../../../../utils';
import { AokInputFieldComponent } from '../../../cdk';
import { SecondFactorAuthOptionsComponent } from '../../../second-factor-auth-options/second-factor-auth-options.component';
import { AokStepperComponent, StepChangeEvent } from '../../../stepper/stepper.component';
import { AokBaseRegistrationDialog } from '../base-registration.component';

@Component({
  selector: 'aok-registration-dialog',
  styleUrls: ['./registration-dialog.component.scss'],
  templateUrl: './registration-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AokRegistrationDialog
  extends AokBaseRegistrationDialog
  implements OnInit, DialogBase<Partial<AokUserRegistration>>
{
  @Input() formValue: AokUserRegistration;
  @Input() isCardOrderingEnabled: boolean;

  @ViewChild(AokStepperComponent) stepper: AokStepperComponent;
  @ViewChildren('userData') userDataFields: QueryList<AokInputFieldComponent>;
  @ViewChild(SecondFactorAuthOptionsComponent) secondFactorAuthOptionsComponent: SecondFactorAuthOptionsComponent;
  @ViewChildren('orgData') orgDataFields: QueryList<AokInputFieldComponent>;
  @ViewChildren(AokFocusFirstChildDirective) stepsWithFocusFirstChild: QueryList<AokFocusFirstChildDirective>;

  public readonly formGroup = createRegistrationRequestForm(this.specializationOptions);
  public formLabels = secondFactorAuthFormLabels;

  protected readonly secondFactorAuthHint = secondFactorAuthHint;

  constructor(
    public readonly dialogRef: DialogRef<Partial<AokUserRegistration>>,
    protected orgClient: AokOrgClient,
    protected keycloak: KeycloakService,
    protected userClient: AokUserClient,
    protected userRegistrationClient: AokUserRegistrationClient,
    protected changeDetectorRef: ChangeDetectorRef,
    protected errorDetectorRef: FormErrorDetectorRef,
    protected toastService: AokToastService,
    protected specializationState: AokSpecializationState,
    protected cd: ChangeDetectorRef
  ) {
    super(dialogRef, specializationState, cd, orgClient);

    this.setupLanrSpecializationHandler();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.formValue == null) {
      const preRegistrationUserData = extractAokRegistrationUserData(
        this.keycloak.getKeycloakInstance().tokenParsed as AokKeycloakTokenParsed
      );
      this.formGroup.get('userData')?.patchValue(preRegistrationUserData);
    } else {
      // TODO do we need to path at the beginning? - check how to create form control directly with the correct values
      this.formGroup.patchValue(
        createRegistrationRequestForm(this.specializationOptions, this.formValue).getRawValue()
      );
    }

    this.formGroup
      .get('userData')
      .valueChanges.pipe(takeUntil(this.destroys))
      .subscribe(() => {
        this.validateUserData(true);
      });

    this.formGroup
      .get('organizationData')
      .valueChanges.pipe(takeUntil(this.destroys))
      .subscribe(() => {
        this.validateOrgData(true);
      });
  }

  public validateUserData(byValueChanges?: boolean): boolean {
    if (!byValueChanges) {
      this.errorDetectorRef.detectErrors();
    }
    this.bannerLinks = this.getElementLinksFromInvalids(
      [...this.userDataFields, ...(this.secondFactorAuthOptionsComponent?.inputFieldComponents ?? [])],
      this.formGroup.get('userData') as UntypedFormGroup
    );
    return this.validate(this.bannerLinks, byValueChanges);
  }

  public validateOrgData(byValueChanges?: boolean): boolean {
    if (!byValueChanges) {
      this.errorDetectorRef.detectErrors();
    }
    this.bannerLinks = this.getElementLinksFromInvalids(
      this.orgDataFields,
      this.formGroup.get('organizationData') as UntypedFormGroup
    );
    return this.validate(this.bannerLinks, byValueChanges);
  }

  /**
   * Build registration object from form
   * @param interim - check whether we need final object for submitting or saving intermediary object
   */
  protected buildRegistration(interim = false): AokUserRegistration {
    const registration = super.buildRegistration(interim) as AokUserRegistration;
    if (!interim) {
      registration.userData.userType = KnownUserType.Doctor;
    }
    if (registration.userData.authOption === AuthOption.SMS) {
      registration.userData.secondFactorPhoneNumber = sanitizePhoneNumber(
        registration.userData.secondFactorPhoneNumber
      );
    }

    return registration;
  }

  protected handleStepChange(event: StepChangeEvent): void {
    let changeStep = false;

    if (event.newStep.index <= event.currentStep.index) {
      changeStep = true;
    } else {
      if (event.currentStep.index === 0) {
        changeStep = this.validateUserData(false);
      } else if (event.currentStep.index === 1) {
        changeStep = this.validateOrgData(false);
      }
    }

    if (changeStep) {
      this.changeStep(event.newStep);
    }
  }

  protected handleStepChanged(event: StepChangeEvent): void {
    // reset possible errors when a new step is opened - only needed for the second step as you can go back and forth
    if (event.newStep.index === 1) {
      resetErrors(this.formGroup.get('organizationData'));
    }
    setTimeout(() => this.stepsWithFocusFirstChild.get(event.newStep.index).setFocus());
  }

  protected async submit(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    this.isErrorBannerEnabled = false;
    const registration = this.buildRegistration();

    try {
      let result;
      if (this.formValue?.id == null) result = await lastValueFrom(this.userRegistrationClient.create(registration));
      else result = await lastValueFrom(this.userRegistrationClient.updateSessionRegistration(registration));
      this.dialogRef.dispose(result);
    } catch (e) {
      this.toastService.createErrorToast(
        'Technischer Fehler',
        'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
      );
    }
  }
}
