import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AokMenuButtonEntry } from '@aok/common';
import { AokMenuComponent } from './menu/menu.component';

@Component({
  selector: 'aok-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AokMenuButtonComponent implements AfterViewInit, DoCheck {
  @Input() entries: AokMenuButtonEntry[] = [];
  @Input() showOpenState = false;
  @Output() selected: EventEmitter<AokMenuButtonEntry> = new EventEmitter<AokMenuButtonEntry>();
  public menuTrigger: MatMenuTrigger;
  public aokm: AokMenuComponent;
  protected triggerClass: string;

  @ViewChild('menuTrigger', { static: false }) set contentMenuTrigger(content: MatMenuTrigger) {
    if (content) {
      // initially setter gets called with undefined
      this.menuTrigger = content;
      this.cd.detectChanges();
    }
  }

  @ViewChild('aokm', { static: false }) set contentMenu(content: AokMenuComponent) {
    if (content) {
      // initially setter gets called with undefined
      this.aokm = content;
      this.cd.detectChanges();
    }
  }

  constructor(private cd: ChangeDetectorRef, private ref: ElementRef) {
    /*
     We don't need a new @Input var, to use the classes on a child.
     So I have removes the @Input var to a normal string var and checks directly the classes
     */
    this.triggerClass = ref.nativeElement.className;
  }

  ngDoCheck(): void {
    if (this.ref.nativeElement.className !== this.triggerClass) {
      this.triggerClass = this.ref.nativeElement.className;
      this.cd.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.updateView();
  }

  public onSelect(entry: AokMenuButtonEntry): void {
    this.selected.emit(entry);
    this.menuTrigger.closeMenu();
  }

  public updateView(): void {
    this.cd.markForCheck();
  }
}
