<div [id]="id || sectionLabel" role="table" [attr.aria-labelledby]="sectionLabel">
  <div class="header">
    <h5>{{ sectionLabel }}</h5>
    <div>
      <div *ngIf="status && status.label">
        <div *ngIf="status.status" [ngClass]="status.status"></div>
        <span>{{ status.label }}</span>
      </div>
      <aok-menu-button
        *ngIf="entries?.length"
        [showOpenState]="true"
        [entries]="entries"
        (selected)="selected.emit($event)"
      >
        {{ optionTitle || 'Optionen' }}
      </aok-menu-button>
    </div>
  </div>

  <div role="row" *ngFor="let entry of values; trackBy: trackByFn" class="entry-flex-container">
    <span role="rowheader" class="aok-bold">
      {{ entry.label }}
      <aok-popover
        *ngIf="entry.popoverHeader"
        [id]="entry.id + 'Popover'"
        width="384"
        [direction]="POPOVER_DIRECTION.RightTop"
        [xaxis]="3"
        [yaxis]="-2"
      >
        <aok-popover-content-template [label]="entry.popoverHeader" [list]="entry.popoverList">
        </aok-popover-content-template>
      </aok-popover>
    </span>

    <span role="cell" [id]="entry.id">{{ entry.value }}</span>
  </div>
</div>
