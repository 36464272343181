import { Injectable } from '@angular/core';
import {
  AokFullAddressPipe,
  AokOrg,
  AokOrgTypeIntlPipe,
  AokRegistrationToOrganisation,
  AokRuntimeProfileState,
  AokUserRegistrationStatus,
  ContextState,
  CurrentUserState,
  TableTypeRow,
} from '@aok/common';
import { filter, tap } from 'rxjs/operators';
import { TableStatus } from '../components';
import { ContextService } from './context.service';
import { DialogManagerService } from './dialog-manager.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class PracticeDataService {
  constructor(
    private dialogService: DialogManagerService,
    private contextService: ContextService,
    private runtimeProfile: AokRuntimeProfileState,
    private currentUser: CurrentUserState,
    private orgTypePipe: AokOrgTypeIntlPipe,
    private fullAddressPipe: AokFullAddressPipe,
    private contextState: ContextState,
    private profileService: ProfileService
  ) {}

  public openDialogToDeletePracticeRelation(bsnr: string): void {
    this.dialogService
      .openDialogToDeletePracticeRelation(this.currentUser.snapshot.id, bsnr)
      .subscribe((success: boolean) => {
        if (success) {
          this.contextService.retrieveContextOptions().subscribe(() => {
            if (this.contextState.bsnr === bsnr) {
              this.contextState.clearSelectedContext();
            }
            this.profileService.reloadProfileOrganisations();
          });
        }
      });
  }

  public openDialogToDeleteMFAJoinBSNRRequest(id: number): void {
    this.dialogService.openDialogToDeleteMFAJoinBSNRRequest(id).subscribe((success: boolean) => {
      if (success) {
        this.profileService.reloadMFARequests();
      }
    });
  }

  public addPendingBsnrs(): void {
    const keycloakId = this.currentUser.snapshot.keycloakId;
    const pendingBsnrs = this.currentUser.snapshot.practitionerResource?.pendingSamlBsnrs || [];
    if (pendingBsnrs.length) {
      this.dialogService
        .openAddPracticesOverlay(keycloakId, pendingBsnrs)
        .pipe(
          filter((success) => success),
          tap(() => {
            this.profileService.reloadProfileOrganisations();
            this.currentUser.retrieveCurrentUser().subscribe();
          })
        )
        .subscribe();
    }
  }

  public sendRequestForUpdateOrg(orgId: number): void {
    const org = this.profileService.getOrganisationByIdOrSelected(orgId);
    const recipient = this.runtimeProfile.get<string>('contactEmail'); // 'aok-arztportal@nds.aok.de';
    const subject = 'Änderung der Praxisdaten im AOK-Arztportal';
    const msg: string =
      'Sehr geehrtes Arztportal-Team,\n\n' +
      'hiermit sende ich Ihnen eine Anfrage zur Änderung der Praxisdaten für meine Praxis mit der BSNR ' +
      org?.bsnr +
      '.\n\n' +
      'Bitte übernehmen Sie die folgenden Daten in Ihr System:\n' +
      '\t→Bitte fügen Sie hier Ihre neuen Daten ein\n\n' +
      'Mit freundlichen Grüßen\n\n' +
      '---\n\n' +
      'Daten zur Zuordnung der Anfrage:\n' +
      '-LANR ' +
      this.currentUser.snapshot.practitionerResource?.lanr +
      '\n' +
      '-BSNR ' +
      org?.bsnr;

    window.location.href = 'mailto:' + recipient + '?subject=' + subject + '&body=' + encodeURIComponent(msg);
  }

  public getTableData(org: AokRegistrationToOrganisation | AokOrg): TableTypeRow[] {
    if ('bsnr' in org) {
      const { organizationType, address, name } = org;
      return [
        { label: 'Betriebsstättennnummer', value: org.bsnr },
        { label: 'Art der Praxis', value: this.orgTypePipe.transform(organizationType) },
        { label: 'Name der Praxis', value: name },
        { label: 'Praxisanschrift', value: this.fullAddressPipe.transform(address) },
      ];
    } else {
      return [
        { label: 'Betriebsstättennnummer', value: org.organizationData.bsnr },
        { label: 'Art der Praxis', value: this.orgTypePipe.transform(org.organizationData.organizationType) },
        { label: 'Name der Praxis', value: org.organizationData.organizationName },
        { label: 'Praxisanschrift', value: this.fullAddressPipe.transform(org.organizationData.address) },
      ];
    }
  }

  public getStatus(status: AokUserRegistrationStatus): TableStatus {
    if ([AokUserRegistrationStatus.Pending, AokUserRegistrationStatus.InternalReview].includes(status)) {
      return { status: 'grey', label: 'In Prüfung' };
    } else if (status === AokUserRegistrationStatus.Rejected) {
      return { status: 'red', label: 'Antrag abgelehnt' };
    }
  }
}
