import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AokMenuButtonEntry, PopoverDirection, TableTypeRow } from '@aok/common';

export interface TableStatus {
  label: string;
  status?: 'grey' | 'red';
}

@Component({
  selector: 'aok-cockpit-table-type',
  styleUrls: ['table-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'table-type.component.html',
})
export class TableTypeComponent {
  @Input() id: string;
  @Input() sectionLabel: string;
  @Input() values: Array<TableTypeRow> = [];
  @Input() entries: AokMenuButtonEntry[] = [];
  @Input() optionTitle: string;
  @Input() status?: TableStatus;

  @Output() selected: EventEmitter<AokMenuButtonEntry> = new EventEmitter<AokMenuButtonEntry>();
  protected readonly POPOVER_DIRECTION = PopoverDirection;

  constructor() {}

  protected trackByFn(index: number, tableRow: TableTypeRow): string {
    return tableRow.id || tableRow.label;
  }
}
