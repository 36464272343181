import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'aok-step',
  template: ` <div *ngIf="active">
    <ng-content></ng-content>
  </div>`,
  exportAs: 'step',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class AokStepComponent {
  public visited = false;
  public valid = false;
  public index = 0;
  @Input() iconName: string;
  @Input() title: string;
  @Input() description: string;
  @Input() active = false;
  @Input() allowLinebreakInTitle = true;
  private _validate: () => boolean;

  get validate(): () => boolean {
    this.valid = this._validate ? this._validate() : true;
    this.cd.markForCheck();
    return this._validate;
  }

  @Input() set validate(validationFunction: () => boolean) {
    this._validate = validationFunction;
    this.cd.markForCheck();
  }

  // TODO check how to transmit change detection without making this cd public
  constructor(public cd: ChangeDetectorRef) {}
}
