import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  A11yUtilsModule,
  AokContactClient,
  AokFullNamePipe,
  AokToastService,
  AokUserClient,
  ContactRequestSchema,
  ContextState,
  CurrentUserState,
  DialogBase,
  DialogRef,
  DropdownSchema,
  email,
  phoneNumber,
  sanitizePhoneNumber,
  scrollToTop,
  TrimControlDirective,
} from '@aok/common';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ContactCategory, ErrorState } from '../../../schema';
import { AokInputFieldModule, AokToTopScrollerModule } from '../../cdk';
import { AokCheckboxModule } from '../../checkbox/checkbox.module';
import { AokDropdownModule } from '../../dropdown/dropdown.module';
import { FormControlErrorModule } from '../../form-control-error/module';
import { AokNotificationBannerModule } from '../../notification-banner/notification-banner.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';

@Component({
  selector: 'aok-cockpit-report-contact',
  styleUrls: ['./report-contact-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './report-contact-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    AokToTopScrollerModule,
    AokDropdownModule,
    AokInputFieldModule,
    ReactiveFormsModule,
    AokCheckboxModule,
    FormControlErrorModule,
    AokNotificationBannerModule,
    A11yUtilsModule,
    TrimControlDirective,
    A11yModule,
    AokDialogLayoutModule,
  ],
})
export class ReportContactDialog implements OnInit, OnDestroy, DialogBase<void> {
  @Input() public enabledCategories: Record<ContactCategory, boolean>;
  public userOptions: BehaviorSubject<DropdownSchema[]> = new BehaviorSubject([]);
  public categories: DropdownSchema[];

  public ErrorStateEnum = ErrorState;
  public isErrorBannerEnabled = false;
  public errorState: ErrorState = ErrorState.default;
  public readonly formGroup = this.setFormGroup();
  private ngDestroys = new Subject<void>();

  constructor(
    public readonly dialogRef: DialogRef<void>,
    private userClient: AokUserClient,
    private currentUser: CurrentUserState,
    private fullNamePipe: AokFullNamePipe,
    private contactClient: AokContactClient,
    private toastService: AokToastService,
    private contextState: ContextState,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setCategories();

    if (this.contextState.bsnr && this.currentUser) {
      this.loadPartners();
      this.prefillFormGroup();
    }

    this.valueChangeListener('contactOptionPhone', 'contactOptionEmail', 'phone');
    this.valueChangeListener('contactOptionEmail', 'contactOptionPhone', 'email');
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  public submit(): void {
    // Update all keys before submitting the form, so that the valid state of the form is accurate
    Object.keys(this.formGroup.controls).forEach((key) => this.formGroup.get(key).updateValueAndValidity());
    if (this.formGroup.valid) {
      this.isErrorBannerEnabled = false;
      this.contactClient
        .submit({
          reportCategory: this.formGroup.get('thema').value,
          message: this.formGroup.get('message').value,
          lanrContext: this.currentUser.lanr || this.contextState.practitionerLanr,
          bsnrContext: this.contextState.bsnr,
          phoneCheckbox: this.formGroup.get('contactOptionPhone').value,
          phoneNumber: sanitizePhoneNumber(this.formGroup.get('phone').value),
          emailCheckbox: this.formGroup.get('contactOptionEmail').value,
          email: this.formGroup.get('email').value,
          selectedUserId: this.formGroup.get('partner').value,
        } as ContactRequestSchema)
        .pipe(
          catchError(() => {
            this.toastService.createErrorToast(
              'Vorgang fehlgeschlagen',
              'Die Daten konnten nicht gesendet werden. Bitte versuchen Sie es erneut.'
            );

            return EMPTY;
          })
        )
        .subscribe(() => {
          this.toastService.createSuccessToast(
            'Nachricht erfolgreich übermittelt',
            'Vielen Dank für Ihre Nachricht. Wir werden uns in Kürze bei Ihnen melden.'
          );
          this.dialogRef.dispose();
        });
    } else {
      this.handleInvalidForm();
    }
  }

  protected openPrivacyPage(): void {
    this.router.navigate(['/datenschutz']).then(() => {
      this.dialogRef.dispose();
    });
  }

  private loadPartners = (): void => {
    this.userClient.list({ bsnr: this.contextState.bsnr, size: 1000 }).subscribe((data) => {
      const selectableUsers = data._embedded.items.map((item) => {
        return {
          label: this.fullNamePipe.transform(item, 'TT FF LL'),
          value: item.id,
        };
      });
      this.userOptions.next(selectableUsers);
    });
  };

  private handleInvalidForm(): void {
    const errorFieldIds: string[] = Object.keys(this.formGroup.controls).filter(
      (key) => this.formGroup.get(key).invalid
    );

    if (errorFieldIds.length !== 0) {
      if (errorFieldIds.length > 1) {
        this.errorState = ErrorState.multiError; //Sets the ErrorBanner type
      } else {
        this.errorState = ErrorState.error; //Sets the ErrorBanner type
      }
    }
    this.isErrorBannerEnabled = true;
    scrollToTop();
  }

  private prefillFormGroup(): void {
    if (this.currentUser.snapshot.mobilePhone) {
      this.formGroup.get('contactOptionPhone').setValue(true);
      this.formGroup.get('phone').setValue(this.currentUser.snapshot.mobilePhone);
    }
    this.formGroup.get('contactOptionEmail').setValue(true);
    // email set value makes the form true
    this.formGroup.get('email').setValue(this.currentUser.snapshot.email);
    this.formGroup.get('partner').setValue(this.currentUser.snapshot.id);
    this.setCategory();
  }

  /**
   * Preselect category option based on url segments
   */
  private setCategory(): void {
    const segments = window.location.pathname.split('/');
    const category = segments.pop();
    if (category) {
      const themaValue = this.categories.find((item) => item.label.toLowerCase() === category.toLowerCase());
      this.formGroup.get('thema').setValue(themaValue?.value);
    }
  }

  private valueChangeListener(control: string, relatedControl: string, input: string): void {
    this.formGroup
      .get(control)
      .valueChanges.pipe(takeUntil(this.ngDestroys))
      .subscribe((value) => {
        if (input === 'email') {
          this.checkCodependency(control, relatedControl, input, email, value);
        }
        if (input === 'phone') {
          this.checkCodependency(control, relatedControl, input, phoneNumber, value);
        }
      });
  }

  /**
   * Check validation for two inputs that have a dependent relationship. Both items can be selected, but at least one is required.
   * @param targeted the control name of the checkbox on which the action is listened on
   * @param related the control name of the dependent checkbox whose behaviour is based on the targeted one
   * @param input the control name of the input field related to the targeted control
   * @param inputValidator additional validators for the input field
   * @param targetValue the new value of the targeted control
   * @private
   */
  private checkCodependency(
    targeted: string,
    related: string,
    input: string,
    inputValidator: ValidatorFn,
    targetValue: boolean
  ): void {
    const inputControl: AbstractControl = this.formGroup.get(input);
    const targetedControl: AbstractControl = this.formGroup.get(targeted);
    const relatedControl: AbstractControl = this.formGroup.get(related);

    if (targetValue) {
      // if the targeted control is enabled, the related input field becomes mandatory
      inputControl.setValidators([Validators.required, inputValidator]);

      // if the related control is unchecked, clear the validations, as we have at least one option selected, thus the
      // required condition is satisfied
      if (!relatedControl.value) {
        this.clearValidators(relatedControl);
      }
    } else {
      // if the targeted control is disabled and the related control is not, the required condition is not satisfied,
      // we enable validations on both controls
      if (!relatedControl.value) {
        if (!targetedControl.validator) {
          targetedControl.setValidators(Validators.requiredTrue);
          targetedControl.updateValueAndValidity();
        }

        if (!relatedControl.validator) {
          relatedControl.setValidators(Validators.requiredTrue);
          relatedControl.updateValueAndValidity();
        }
      } else if (targetedControl.validator) {
        // if the targeted control is disabled, but the related one is checked, we clear the validations of the targeted
        // one, as the required condition is satisfied
        this.clearValidators(targetedControl);
      }

      this.clearValidators(inputControl);
    }
  }

  private clearValidators(control: AbstractControl): void {
    control.clearValidators();
    control.updateValueAndValidity();
  }

  private setCategories(): void {
    this.categories = Object.entries(ContactCategory)
      .map(([key, value]) => {
        return {
          label: value,
          value: key,
        } as DropdownSchema;
      })
      .filter((entry) => this.enabledCategories[entry.label]);
  }

  private setFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      thema: new FormControl<ContactCategory | string>(null, [Validators.required]),
      message: new FormControl<string>('', [Validators.required]),
      partner: new FormControl<number>(null, [Validators.required]),
      contactOptionPhone: new FormControl<boolean>(false, Validators.requiredTrue),
      phone: new FormControl<string>('', [Validators.required, phoneNumber]),
      contactOptionEmail: new FormControl<boolean>(true, Validators.requiredTrue),
      email: new FormControl<string>(null, {
        validators: [Validators.required, email],
        updateOn: 'blur',
      }),
    });
  }
}
