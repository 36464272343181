<div class="aok-container">
  <div *ngIf="hasAdditionalBSNRs | async" class="tw-flex tw-items-center tw-justify-between tw-mt-5">
    <p>Im KVN-Portal ist eine weitere Betriebsstätte vorhanden.</p>
    <button (click)="addPendingBSNRs()">Daten anzeigen</button>
  </div>

  <h4>Übersicht Praxisdaten</h4>
  <ng-container>
    <ng-container *ngTemplateOutlet="addOrganisation"></ng-container>
  </ng-container>

  <h4 class="selectedOrganisation">Aktuell ausgewählte Praxis</h4>

  <aok-cockpit-table-type
    [sectionLabel]="'Praxisdaten'"
    [values]="selectedPracticeData"
    [entries]="displayedOptions"
    (selected)="$event['action'](selectedOrg?.bsnr)"
  >
  </aok-cockpit-table-type>
  <!--mfaRequestsData-->
  <ng-container *ngIf="requestJoinBSNRData.length">
    <h4>Kürzlich beantragte Praxiszugehörigkeit</h4>

    <aok-cockpit-table-type
      *ngFor="let request of requestJoinBSNRData; let i = index; trackBy: joinBsnrRequestsTrackBy"
      [id]="request.bsnr"
      [sectionLabel]="request.name"
      [values]="request.value"
      [entries]="request.options"
      (selected)="$event['action'](isAssistant ? request.id : request.request)"
      [status]="request.status"
    >
    </aok-cockpit-table-type>
  </ng-container>

  <ng-container *ngIf="unselectedPracticeData.length">
    <h4>Weitere Praxen</h4>

    <aok-cockpit-table-type
      *ngFor="let nonSelectedData of unselectedPracticeData; let i = index; trackBy: unselectedOrganizationsTrackBy"
      [sectionLabel]="nonSelectedData.org.name"
      [values]="nonSelectedData.value"
      [entries]="displayedOptions"
      (selected)="$event['action'](nonSelectedData.org.bsnr)"
    >
    </aok-cockpit-table-type>
  </ng-container>

  <ng-container>
    <ng-container *ngTemplateOutlet="addOrganisation"></ng-container>
  </ng-container>
</div>

<ng-template #addOrganisation>
  <div class="addOrganisation">
    <p>
      {{ isAssistant ? 'Möchten Sie einer weiteren Praxis beitreten?' : 'Möchten Sie eine neue Praxis hinzufügen?' }}
    </p>
    <button class="secondary" (click)="openAddPracticeDialog()">
      {{ isAssistant ? 'Weiterer Praxis beitreten' : 'Praxis hinzufügen' }}
    </button>
  </div>
</ng-template>
