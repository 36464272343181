import { InjectionToken } from '@angular/core';
import { KeycloakOptions } from 'keycloak-angular';
import { MatomoConfiguration } from 'ngx-matomo';

export const ENVIRONMENT = new InjectionToken<EnvironmentSchema>('ENVIRONMENT');

export interface EnvironmentSchema {
  id: 'dev' | 'test' | 'int' | 'demo' | 'pre-prod' | 'prod' | string;
  baseUrl: string;
  production: boolean;
  keycloak: KeycloakOptions;
  tiConfig: Partial<TIConfig>;
  matomoConfig: Partial<MatomoConfiguration>;
  matomoTagManagerConfig: Record<string, string>;
  svsAppEntrypointUrl: string;
  svsTIUrl?: string;
  cmsRedirectUrl: string;
  loginWithKvnUrl: string;
  /** Temporary feature flag for enabling or disabling the app hub functionality */
  appHub: boolean;
  enableContractsOverview: boolean;
  enableFamilyDoctorCenterCare: boolean;
  /** Temporary feature flag for enabling or disabling the dashboard reminders functionality */
  enableNotifications: boolean;
  enableFeedback: boolean;
  enableContactRequest: boolean;
  enableHZVReports: boolean;
  enableArzneimittelReports: boolean;
  enableHeilmittelReports: boolean;
  checkTI: boolean;
  enableSendDmpMessageToAOK: boolean;
  enableDmpCaseManagement: boolean;
  enableBenefits: boolean;
  enableDisturbanceMessage: boolean;
  testEnvVariable?: string;
  cardReaderApiUrl?: string;
}

export interface TIConfig {
  baseUrl: string;
}
