import { CommonModule } from '@angular/common';
import { Component, HostBinding, Inject, Input, Optional, ViewEncapsulation } from '@angular/core';
import { SVG_ICON_SIZE, SVG_ICON_SIZE_ALIASES, SvgIconSizeAliases } from '../../schemas';
import { SvgIconHost, SvgIconRegistry } from '../../services';
import { isString } from '../../utils';

@Component({
  selector: 'aok-svg-icon',
  styleUrls: ['./svg-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './svg-icon.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class AokSvgIconComponent {
  @Input() id: string;
  @Input() name: string;
  @Input() title: string;
  @Input()
  @HostBinding('style.height.px')
  @HostBinding('style.width.px')
  size: string | number;
  @Input() viewBox: string;

  get registered(): boolean {
    return this.id !== undefined || this.name !== undefined;
  }

  get href(): string {
    if (!this.name) {
      this.name = this.id;
    }
    return this.name && `#${this.name}`;
  }

  get sizeAsNumber(): number {
    const size = this.sizeAliases != null && isString(this.size) ? this.sizeAliases[this.size] : this.size;
    return isString(size) ? parseFloat(size) : size;
  }

  constructor(
    @Optional() @Inject(SVG_ICON_SIZE_ALIASES) protected readonly sizeAliases: /* @dynamic */ SvgIconSizeAliases,
    @Optional() @Inject(SVG_ICON_SIZE) size: /* @dynamic */ number | string,
    // next two imports are needed to be initialized even if not used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    host: SvgIconHost,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    registry: SvgIconRegistry
  ) {
    if (size != null) this.size = size;
  }
}
