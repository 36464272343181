import { Platform } from '@angular/cdk/platform';
import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  AokCookieUtils,
  AokToastService,
  AppLoadingStateEnum,
  AppState,
  ContextState,
  CurrentUserState,
  getExpirationDate,
  getParentDomain,
  getSafariVersion,
  getUserTypeText,
  scrollToTop,
  shouldCheckTI,
} from '@aok/common';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { MatomoTracker } from 'ngx-matomo';
import { EMPTY, from, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { oldSafariVersion } from './config/app.config';
import { MatomoCustomVariable } from './config/matomo.config';
import { TiService } from './services/ti.service';

export const HIDE_OLD_SAFARI_BANNER = 'HIDE_OLD_SAFARI_BANNER';

declare global {
  interface Window {
    docportal_userrole: string;
  }
}

@Component({
  selector: 'aok-cockpit-root',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public isAppLoaded$: Observable<boolean>;
  public requiresInitialization = true;

  // TODO remove when GESA no longer needs this page
  @HostBinding('class.is-gesa') public isGesa: boolean;

  private worker?: Worker;
  private ngDestroys = new Subject<void>();

  private get isToastCanceled(): boolean {
    return AokCookieUtils.get(HIDE_OLD_SAFARI_BANNER)?.trim() === 'true';
  }

  constructor(
    readonly currentUser: CurrentUserState,
    protected matomoTracker: MatomoTracker,
    private keycloak: KeycloakService,
    private contextState: ContextState,
    private tiService: TiService,
    private appState: AppState,
    private router: Router,
    private platform: Platform,
    private toastService: AokToastService
  ) {
    if (shouldCheckTI(environment)) {
      this.worker = this.tiService.setupTIConnectionChecker(environment.tiConfig.baseUrl);
    }
    this.isAppLoaded$ = this.appState.isLoaded;
  }

  ngOnInit(): void {
    this.matomoInit();
    this.checkRouteRequirements();
    this.checkRouteRequirementsOnNavigationStart();

    this.keycloakUserInit()
      .pipe(tap(() => this.clearContextOnTimeout()))
      .subscribe(() => {
        if (this.platform.SAFARI) {
          this.checkSafariVersion();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.worker) {
      this.worker.terminate();
    }
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  onActivate(): void {
    scrollToTop();
  }

  // TODO can this be the userService loadKeycloakInstance?
  private keycloakUserInit(): Observable<KeycloakEvent> {
    if (this.keycloak.getKeycloakInstance() == null) {
      return this.keycloak.keycloakEvents$.pipe(
        filter((e) => e.type === KeycloakEventType.OnReady),
        take(1)
      );
    }
    return EMPTY;
  }

  private matomoInit(): void {
    this.currentUser
      .asObservable()
      .pipe(filter((user) => user != null))
      .subscribe(() => this.provideMetadataForMatomo());
  }

  private clearContextOnTimeout(): void {
    this.keycloak.keycloakEvents$
      .pipe(
        takeUntil(this.ngDestroys),
        filter((e) => e.type === KeycloakEventType.OnAuthLogout)
      )
      .subscribe(() => {
        this.appState.setLoadingState(AppLoadingStateEnum.LOADING);
        this.currentUser.clear();
        this.contextState.clearSelectedContext();
      });
  }

  private provideMetadataForMatomo(): void {
    const userRole = getUserTypeText(this.currentUser.snapshot.userType, true);
    const userId = this.currentUser.snapshot.email;
    // enables tracking the recurring visitors by their id. The id will be fully anonymized in Matomo
    this.matomoTracker.setUserId(userId);
    // allows the assignment of the user role to a visitor profile. So Matomo can show the role when
    // presenting details about a visit (visitor's log)
    this.matomoTracker.setCustomVariable(1, MatomoCustomVariable.USER_ROLE, userRole, 'page');
    // allows the usage of the user role as a custom dimension in Matomo
    this.matomoTracker.setCustomDimension(1, userRole);
    // this global variable will be used by Matomo to keep track on custom events (which roles triggered them)
    window.docportal_userrole = userRole;
  }

  private checkRouteRequirements(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.requiresInitialization = !event.url.includes('publik');
      // TODO remove when GESA no longer needs this page
      this.isGesa = event.url.includes('publik/gesa');
    });
  }

  private checkSafariVersion(): void {
    from(this.keycloak.isLoggedIn()).subscribe((isLogged) => {
      if (!isLogged || this.isToastCanceled) {
        return;
      }

      if (getSafariVersion() < getSafariVersion(oldSafariVersion)) {
        this.createToast();
      } else {
        AokCookieUtils.remove(HIDE_OLD_SAFARI_BANNER);
      }
    });
  }

  private createToast(): void {
    this.toastService
      .createErrorToast(
        'Ihre Browser-Version wird nicht mehr unterstützt',
        'Aktualisieren Sie Ihren Browser auf die neueste Version, um Einschränkungen in der Bedienbarkeit zu vermeiden.'
      )
      .subscribe(() => {
        AokCookieUtils.set(
          HIDE_OLD_SAFARI_BANNER,
          true.toString(),
          `expires=${getExpirationDate(48)};path=/;domain=${getParentDomain()}`
        );
      });
  }

  private checkRouteRequirementsOnNavigationStart(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((val: NavigationStart) => {
      if (val.url.includes('kontext')) {
        this.contextState.clearSelectedContext();
      }
    });
  }
}
