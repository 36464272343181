import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  A11yUtilsModule,
  AokAuthOptionPipeModule,
  AokContractTypeIntlPipe,
  AokDoctorTypeIntlPipe,
  AokFullNamePipeModule,
  AokOrgTypeIntlPipe,
  AokPopoverComponent,
  AokSvgIconComponent,
  AokUserSalutationIntlPipe,
  AokZeroPadPipeModule,
  DialogOverlayModule,
  TrimControlDirective,
} from '@aok/common';
import { AokStepDefModule } from '../../../../directives';
import { AokHintModule, AokInputFieldModule } from '../../../cdk';
import { AokDropdownModule } from '../../../dropdown/dropdown.module';
import { FormControlErrorModule } from '../../../form-control-error/module';
import { AokNotificationBannerModule } from '../../../notification-banner/notification-banner.module';
import { PracticeFormComponent } from '../../../practice-form/practice-form.component';
import { AokRadioModule } from '../../../radio-button/radio.module';
import { SecondFactorAuthOptionsComponent } from '../../../second-factor-auth-options/second-factor-auth-options.component';
import { AokStepComponent } from '../../../stepper/step.component';
import { AokStepperComponent } from '../../../stepper/stepper.component';
import { AokDialogLayoutModule } from '../../dialog-layout/dialog-layout.module';
import { AokRegistrationDialog } from './registration-dialog.component';

@NgModule({
  declarations: [AokRegistrationDialog],
  imports: [
    DialogOverlayModule,
    AokStepperComponent,
    AokDropdownModule,
    AokRadioModule,
    CommonModule,
    AokHintModule,
    ReactiveFormsModule,
    FormControlErrorModule,
    AokFullNamePipeModule,
    AokZeroPadPipeModule,
    AokStepDefModule,
    AokNotificationBannerModule,
    AokAuthOptionPipeModule,
    AokInputFieldModule,
    A11yModule,
    A11yUtilsModule,
    AokSvgIconComponent,
    AokDialogLayoutModule,
    AokPopoverComponent,
    TrimControlDirective,
    PracticeFormComponent,
    AokUserSalutationIntlPipe,
    AokDoctorTypeIntlPipe,
    AokContractTypeIntlPipe,
    AokOrgTypeIntlPipe,
    SecondFactorAuthOptionsComponent,
    AokStepComponent,
  ],
})
export class AokRegistrationDialogModule {}
