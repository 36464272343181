import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AokUserClient } from '../../clients';
import { countryCodeDE } from '../../constants';
import {
  AokPractitionerSpecializationEntry,
  AokRegistrationOrgData,
  AokUserRegistration,
  AuthOption,
  KnownUserSalutation,
} from '../../schemas';
import { isEmailInUse } from '../validators/is-email-in-use.validator';
import { bsnr, email, houseNumber, lanr9, name, phoneNumber, zipCode } from '../validators/pattern.validator';
import { specialization } from '../validators/specialization.validator';
import { zeroPad } from '../zero-pad.util';
import { matchFormFieldsValidator } from './change-email-form.util';
import { FormSchemaControls } from './form-utils/form-schema-map';

const { required } = Validators;

export function createRegistrationRequestForm(
  specializations: AokPractitionerSpecializationEntry[],
  schema?: Partial<AokUserRegistration>,
  isKVN = false
): UntypedFormGroup {
  return new UntypedFormGroup({
    organizationData: createOrganizationDataForm(schema?.organizationData, isKVN),
    userData: createUserDataForm(specializations, schema, isKVN),
  } as FormSchemaControls<AokUserRegistration>);
}

export function createOrganizationDataForm(schema?: Partial<AokRegistrationOrgData>, isKVN = false): UntypedFormGroup {
  const formGroup = new UntypedFormGroup({
    organizationName: new UntypedFormControl(
      {
        value: schema?.organizationName,
        disabled: isKVN && schema?.organizationName,
      },
      [required]
    ),
    organizationType: new UntypedFormControl(
      {
        value: schema?.organizationType,
        disabled: isKVN && schema?.organizationType,
      },
      [required]
    ),
    bsnr: new UntypedFormControl(
      {
        value: schema?.bsnr,
        disabled: isKVN,
      },
      [required, bsnr]
    ),
    address: new UntypedFormGroup({
      zipCode: new UntypedFormControl(
        {
          value: schema?.address?.zipCode,
          disabled: isKVN && schema?.address?.zipCode,
        },
        [required, zipCode]
      ),
      streetNumber: new UntypedFormControl(
        {
          value: schema?.address?.streetNumber,
          disabled: isKVN && schema?.address?.streetNumber,
        },
        [required, houseNumber]
      ),
      streetName: new UntypedFormControl(
        {
          value: schema?.address?.streetName,
          disabled: isKVN && schema?.address?.streetName,
        },
        [required]
      ),
      location: new UntypedFormControl(
        {
          value: schema?.address?.location,
          disabled: isKVN && schema?.address?.location,
        },
        [required]
      ),
    } as FormSchemaControls<AokRegistrationOrgData['address']>),
  } as FormSchemaControls<AokRegistrationOrgData>);

  return formGroup;
}

export function createUserDataForm(
  specializations: AokPractitionerSpecializationEntry[],
  schema?: Partial<AokUserRegistration>,
  isKVN = false,
  isRegisteredDoctor?: boolean,
  userClient?: AokUserClient
): UntypedFormGroup {
  let salutation;
  if (schema?.userData?.salutation === KnownUserSalutation.Mr) {
    salutation = KnownUserSalutation.Mr;
  } else if (schema?.userData?.salutation === KnownUserSalutation.Mrs) {
    salutation = KnownUserSalutation.Mrs;
  } else {
    salutation = null;
  }
  const lanr9Value = getLanr9(schema);
  const formGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl(
      {
        value: schema?.userData?.firstName,
        disabled: isKVN && schema?.userData?.firstName,
      },
      [required, name('firstname')]
    ),
    lastName: new UntypedFormControl(
      {
        value: schema?.userData?.lastName,
        disabled: isKVN && schema?.userData?.lastName,
      },
      [required, name('lastname')]
    ),
    lanr: new UntypedFormControl(
      {
        value: lanr9Value,
        disabled: isKVN,
      },
      [required, lanr9, specialization(specializations)]
    ),
    salutation: new UntypedFormControl(
      {
        value: salutation,
        disabled: isKVN && salutation,
      },
      [required]
    ),
    mobilePhone: new UntypedFormControl(schema?.userData?.mobilePhone, [phoneNumber]),
    title: new UntypedFormControl({
      value: schema?.userData?.title,
      disabled: isKVN && schema?.userData?.title,
    }),
    specialization: new UntypedFormControl(schema?.userData?.specialization?.description, [required]),
    doctorType: new UntypedFormControl(schema?.userData?.doctorType, [required]),
  } as FormSchemaControls<AokUserRegistration['userData']>);

  if (isKVN) {
    formGroup.addControl(
      'kvnPracticeAccountControl',
      new FormControl<boolean>(schema?.userData?.kvnPracticeAccountControl)
    );
    formGroup.addControl(
      'email',
      new FormControl<string>(
        {
          value: schema?.userData?.email,
          disabled: isRegisteredDoctor,
        },
        {
          validators: [email, required],
          asyncValidators: isEmailInUse(userClient),
          updateOn: 'blur',
        }
      )
    );
    formGroup.addControl(
      'emailRepeat',
      new FormControl<string>(schema?.userData?.email, {
        validators: [email, required],
        updateOn: 'blur',
      })
    );
    formGroup.addValidators(matchFormFieldsValidator('email', 'emailRepeat'));
  } else {
    formGroup.addControl(
      'authOption',
      new FormControl<AuthOption | string>(schema?.userData?.authOption ? schema?.userData?.authOption : null, [
        required,
      ])
    );
    formGroup.addControl('countryCode', new FormControl<string>(countryCodeDE, [Validators.required]));
    formGroup.addControl('secondFactorPhoneNumber', new FormControl<string>('', [Validators.required, phoneNumber]));
  }

  return formGroup;
}

const getLanr9 = (schema?: Partial<AokUserRegistration>): string | undefined => {
  if (!schema?.userData?.lanr) {
    return undefined;
  }

  if (schema.userData.lanr.length === 9) {
    return schema.userData.lanr;
  }

  return schema.userData.lanr + zeroPad(schema.userData.specialization?.id);
};
