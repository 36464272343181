import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, EnvironmentSchema } from '../schemas';

@Injectable({
  providedIn: 'root',
})
export class MatomoInjectorService {
  scriptLoaded = false;

  constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSchema) {
    this.initMatomoTagManager();
  }

  /**
   * Initialization of Matomo Tag Manager (MTM)
   * 1. Creation of the html tag to inject Matomo script
   * 2. Pushing init tag required
   */
  initMatomoTagManager(): void {
    if (this.scriptLoaded) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mtm = (window['_mtm'] = window['_mtm'] || []);
    mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });

    const { host, containerId } = this.environment.matomoTagManagerConfig;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = `${host}/js/container_${containerId}.js`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);

    this.scriptLoaded = true;
  }
}
