import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AokBsnrRegistrationClient,
  AokNotificationService,
  AokReminder,
  AokReminderService,
  isAbsoluteURL,
} from '@aok/common';
import { DialogKey } from '../schema';
import { DialogManagerService } from './dialog-manager.service';

@Injectable({ providedIn: 'root' })
export class ReminderActionService {
  constructor(
    private dialogService: DialogManagerService,
    private bsnrRegistrationClient: AokBsnrRegistrationClient,
    private reminderService: AokReminderService,
    private notificationService: AokNotificationService,
    private router: Router
  ) {}

  //TODO: improve handling
  public handleReminderLinkClick(reminder: AokReminder): void {
    if (!reminder?.actionLink) {
      return;
    }

    const [path, fragment] = reminder.actionLink.split('#');
    const urlSegments = path.match(/[^/]+/g);

    if (urlSegments[0] === DialogKey.MFA_REQUEST_TO_JOIN) {
      this.bsnrRegistrationClient.find(Number(urlSegments[1])).subscribe((request) => {
        this.dialogService.openEditMfaRequestToJoinPracticeDialog(request).subscribe((response) => {
          if (response) {
            this.notificationService.getCount().subscribe();
            this.reminderService.retrieveReminders().subscribe();
          }
        });
      });
      return;
    }

    // general handling of internal links
    if (!isAbsoluteURL(reminder.actionLink)) {
      this.router.navigate(urlSegments, {
        fragment,
      });
      return;
    }

    // general handling of absolute URLS
    window.open(reminder.actionLink);
  }
}
