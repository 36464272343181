import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AddressData,
  AokBaseUser,
  AokDoctorRelationship,
  AokMfaToBSNRRegistration,
  AokOrg,
  AokPage,
  AokPageQueryParams,
  AokPractitionerSpecializationEntry,
  AokPractitionerSpecializations,
  AokRegistrationLockStatus,
  AokRegistrationOrgData,
  AokRegistrationToOrganisation,
  AokUser,
  AokUserListOptions,
  AokUserRegistration,
  AuthOption,
  DoctorType,
  HistoryEntry,
  KnownUserSalutation,
  KnownUserType,
  KvnPracticeAccountStatus,
  Seed,
  SvsPractitioner,
} from '../schemas';
import { AokHttpHeaders } from '../schemas/http.schema';
import { AokApiBaseClient } from './api-base.client';

interface EndpointDoctorRelationship {
  links: Array<any>;
  organizationData: AokAssistantOrganisationData;
  userData?: AokAssistantUserData;
}

/**
 * Difference between AokAssistantUserData and AokUser(Doctor)
 * gender missing
 * organizationsIds missing
 * keycloakId missing
 */
// TODO move this interface to a schema file and possibly inherit AokUser from it
interface AokAssistantUserData {
  authOption: AuthOption | string;
  doctorType: DoctorType; // doctorType -> practitionerResource.doctorType
  email: string;
  firstName: string;
  lanr: string; //lanr length 7 // lanr -> practitionerResource.lanr
  lastName: string;
  mobilePhone: string;
  salutation: KnownUserSalutation | string;
  specialization: AokPractitionerSpecializationEntry; // specialization -> practitionerResource.specialization
  title: string;
  userId: number; // userId -> id + userId -> practitionerResource.id
  userType: KnownUserType | string;
}

/**
 * Difference between AokAssistantOrganisationData and AokOrg
 * practitioners missing
 *
 * organizationName vs name
 * organizationId vs id
 */
interface AokAssistantOrganisationData {
  address: AddressData;
  bsnr: string;
  organizationId: number;
  organizationName: string;
  organizationType: string;
}

@Injectable({ providedIn: 'root' })
export class AokUserClient extends AokApiBaseClient {
  list<T extends AokBaseUser = AokUser>(
    options?: AokUserListOptions,
    headers?: AokHttpHeaders
  ): Observable<AokPage<T>> {
    return this.http.get<AokPage<T>>(this.resolveUrl(), {
      headers,
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }

  searchDoctors(options?: AokPageQueryParams): Observable<AokPage<AokUser>> {
    return this.http.get<AokPage<AokUser>>(this.resolveUrl('search-doctors'), {
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }

  public getUserById(id: number): Observable<AokUser> {
    return this.http.get<AokUser>(this.resolveUrl(id.toString()));
  }

  setDisplayCardKey(id: NonNullable<number>, displayCardKey: string): Observable<void> {
    return this.http.put<void>(this.resolveUrl(id.toString(), 'seed'), { seedValue: displayCardKey });
  }

  create(user: Partial<AokUser>): Observable<AokUser> {
    return this.http.post<AokUser>(this.resolveUrl(), user);
  }

  update(id: NonNullable<string | number>, user: Partial<AokUser>): Observable<AokUser> {
    return this.http.put<AokUser>(this.resolveUrl(id.toString()), user);
  }

  updateEmail(email: string): Observable<void> {
    return this.http.patch<void>(this.resolveUrl('my', 'email'), { email });
  }

  delete(id: NonNullable<string | number>): Observable<void> {
    return this.http.delete<void>(this.resolveUrl(id.toString()));
  }

  deleteMyUser(userType?: { userType: string }): Observable<void> {
    return this.http.delete<void>(this.resolveUrl('my'), {
      params: new HttpParams({ fromObject: { ...userType } }),
    });
  }

  listSpecializations(): Observable<AokPractitionerSpecializations> {
    return this.http.get<AokPractitionerSpecializations>(this.resolveUrl('doctor-specializations'));
  }

  listSalutations(): Observable<string[]> {
    return this.http.get<string[]>(this.resolveUrl('salutations'));
  }

  listGenders(): Observable<string[]> {
    return this.http.get<string[]>(this.resolveUrl('genders'));
  }

  listBsnrRegistrations(): Observable<AokPage<AokRegistrationToOrganisation>> {
    return this.http.get<AokPage<AokRegistrationToOrganisation>>(this.resolveUrl('my', 'bsnr-registrations'));
  }

  listAssignedBsnrs(): Observable<any> {
    return this.http.get<any>(this.resolveUrl('relationship'));
  }

  getContextMenuOptions(): Observable<AokDoctorRelationship[]> {
    return this.http.get<EndpointDoctorRelationship[]>(this.resolveUrl('context-options')).pipe(
      map<EndpointDoctorRelationship[], AokDoctorRelationship[]>((relationships) => {
        return relationships.map((relationship) => {
          const { address, bsnr, organizationId, organizationName, organizationType } = relationship.organizationData;
          const transform: AokDoctorRelationship = {
            links: relationship.links,
            organizationData: {
              address,
              bsnr,
              id: organizationId,
              name: organizationName,
              organizationType,
            } as Partial<AokOrg>,
          };
          if ('userData' in relationship) {
            const {
              authOption,
              email,
              firstName,
              userId,
              lastName,
              salutation,
              userType,
              doctorType,
              lanr,
              specialization,
            } = relationship.userData;
            transform.userData = {
              authOption,
              email,
              firstName,
              id: userId,
              lastName,
              salutation,
              userType,
              practitionerResource: {
                doctorType: doctorType,
                id: userId,
                lanr,
                specialization,
              },
            } as Partial<AokUser>;
          }
          return transform;
        });
      })
    );
  }

  isCardOrderingEnabled(): Observable<boolean> {
    return this.http.get<{ enabled: boolean }>(this.resolveUrl('card-ordering')).pipe(map((result) => result.enabled));
  }

  generateDisplayCardKey(): Observable<Seed> {
    return this.http.get<Seed>(this.resolveUrl('seed'));
  }

  updateLock(id: NonNullable<number>, lockStatus: AokRegistrationLockStatus): Observable<AokUserRegistration> {
    return this.http.patch<AokUserRegistration>(this.resolveUrl('lock-status', id.toString()), { lockStatus });
  }

  getRequestedMfa(bsnr: string): Observable<AokPage<AokMfaToBSNRRegistration>> {
    return this.http.get<AokPage<AokMfaToBSNRRegistration>>(this.resolveUrl('my', 'bsnr-registrations', 'requests'), {
      params: new HttpParams({ fromObject: { bsnr } }),
    });
  }

  isEmailInUse(email: string): Observable<boolean> {
    return this.http.post<boolean>(this.resolveUrl('email-exists'), { email });
  }

  removePMFromPractice(id: number, bsnr: string): Observable<void> {
    return this.http.delete<void>(this.resolveUrl(id.toString(), 'bsnr-link'), {
      params: new HttpParams({ fromObject: { bsnr } }),
    });
  }

  getHistoryEntries(keycloakId: string): Observable<HistoryEntry[]> {
    return this.http.get<HistoryEntry[]>(this.resolveUrl('history', 'user', keycloakId));
  }

  addHistoryComment(keycloakId: string, comment: string): Observable<HistoryEntry> {
    return this.http.post<HistoryEntry>(this.resolveUrl('history', 'user'), { keycloakId, comment });
  }

  startInternalCheck(id: number, payload: { reason: string; status: string }): Observable<unknown> {
    return this.http.patch<unknown>(this.resolveUrl('registrations', id.toString(), 'status'), payload);
  }

  endInternalCheck(id: number, status: string): Observable<unknown> {
    return this.http.patch<unknown>(this.resolveUrl('registrations', id.toString(), 'status'), { status });
  }

  updateKvnPracticeAccountControl(kvnPracticeAccountControl: boolean): Observable<unknown> {
    return this.http.patch<unknown>(this.resolveUrl('my', 'kvnPracticeAccountControl'), { kvnPracticeAccountControl });
  }

  getKvnPracticeAccountStatus(): Observable<KvnPracticeAccountStatus> {
    return this.http.get<KvnPracticeAccountStatus>(this.resolveUrl('kvn-lanr-check'));
  }

  getPractitionerDetails(bsnr: string): Observable<SvsPractitioner> {
    return this.http.post<SvsPractitioner>(this.resolveUrl('registrations', 'svsPractitioner'), { bsnr });
  }

  resetSmartphone2FA(id: number): Observable<unknown> {
    return this.http.post<unknown>(this.resolveUrl(id.toString(), 'reset-smartphone-2fa'), {});
  }

  registerKvnAdditionalBsnrs(keycloakId: string, orgs: AokRegistrationOrgData[]): Observable<AokRegistrationOrgData[]> {
    return this.http.patch<AokRegistrationOrgData[]>(this.resolveUrl('keycloak', keycloakId, 'pendingSamlBsnrs'), {
      organizationDataList: orgs,
    });
  }

  createNoteForUser(userKeycloakId: string, note: string): Observable<void> {
    return this.http.post<void>(this.resolveUrl('history', 'note'), { userKeycloakId, note });
  }

  public update2faSmsData(countryCode: string, secondFactorPhoneNumber: string): Observable<AokUser> {
    return this.http.patch<AokUser>(this.resolveUrl('my', 'sms-2fa-data'), {
      countryCode,
      secondFactorPhoneNumber,
    });
  }

  /**
   * Resolves a fully quantified service url using the base ":apiUrl/users"
   *
   * @param segments Additional segments that should be appended to the url
   */
  resolveUrl(...segments: string[]): string {
    // normalizing the base url to ":baseUrl/users"
    return super.resolveUrl('users', ...segments);
  }
}
