import { isString } from './string.util';

const option: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center', inline: 'center' };
const scrollStyles = ['scroll', 'auto'];

export function scrollToTop(parent = document, behavior: ScrollBehavior = 'smooth'): void {
  // check if we are in a dialog
  let elems = parent.getElementsByTagName('dialog-layout') as HTMLCollectionOf<HTMLDialogElement>;
  if (!elems) {
    elems = parent.getElementsByTagName('main') as HTMLCollectionOf<HTMLDialogElement>;
  }
  if (elems && elems.length > 0) {
    setTimeout(() => {
      elems.item(elems.length - 1)?.scroll({ top: 0, behavior: 'smooth' });
    }, 0);
  } else {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: behavior,
      });
    }, 0);
  }
}

export function scrollToElement(element?: string | HTMLElement): void {
  if (isString(element)) {
    const htmlElement: HTMLElement = document.getElementById(element);
    htmlElement?.scrollIntoView(option);
  } else element.scrollIntoView(option);
}

export function scrollToFragmentWithOffset(
  elementId: string,
  offset = 180,
  parent: HTMLElement | Window = window
): void {
  let id = elementId;

  // handle ids starting with a number
  if (/^\d/.test(id)) {
    id = `\\3${id[0]} ${id.slice(1)}`;
  }

  const element: HTMLElement = document.querySelector(`#${id}`);

  if (element) {
    const elementTop = element.offsetTop;

    const scrollToPosition = elementTop - offset;

    parent.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
  }
}

export function findScrollParent(element: HTMLElement | null): HTMLElement | Window {
  const parent = element?.parentElement;
  // if parent is null there is no scroll element, so return window
  if (!parent) return window;
  // can be modified to include also overflowX
  const { overflowY } = getComputedStyle(parent);
  // if parent has either overflowY scroll or auto return parent
  if (scrollStyles.includes(overflowY)) return parent;
  // otherwise continue search
  return findScrollParent(parent);
}
