import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aok-cockpit-gesa-app',
  standalone: true,
  imports: [],
  template: `
    <div class="container">
      <h1>Öffnen der GESA-App...</h1>
    </div>
  `,
  styles: [
    `
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        margin: 0;
        background-color: #005e3f;
      }

      h1 {
        text-align: center;
        font-family: Arial, sans-serif;
        color: #fff;
      }
    `,
  ],
})
export class GesaAppComponent implements OnInit {
  private readonly APP_SCHEME = 'gesa://';
  private readonly IOS_STORE = 'itms-apps://itunes.apple.com/app/id6444763587';
  private readonly ANDROID_STORE = 'https://play.google.com/store/apps/details?id=de.aok.niedersachsen.gesa';
  private readonly REDIRECT_DELAY = 2500; // milliseconds

  ngOnInit(): void {
    this.handleRedirection();
  }

  private handleRedirection(): void {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = /android/.test(userAgent);

    if (isIOS) {
      window.location.href = this.APP_SCHEME;
      setTimeout(() => {
        window.location.href = this.IOS_STORE;
      }, this.REDIRECT_DELAY);
    } else if (isAndroid) {
      window.location.href = this.APP_SCHEME;
      setTimeout(() => {
        window.location.href = this.ANDROID_STORE;
      }, this.REDIRECT_DELAY);
    }
  }
}
