import { EnvironmentSchema, isTIAccessible } from '@aok/common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const $: any;
const matomoBaseUrl = isTIAccessible()
  ? 'https://ti-matomo.arztportal-niedersachsen.de'
  : 'https://matomo.arztportal-niedersachsen.de';

export const environment: EnvironmentSchema = {
  id: '$`ENV_ID`',
  production: true,
  baseUrl: '$`BASE_HREF`',
  keycloak: {
    config: {
      url: '$`KEYCLOAK_URL`',
      clientId: 'aokn-docportal-ui',
      realm: 'AOKNAPE',
    },
    enableBearerInterceptor: true,
    bearerExcludedUrls: ['/assets'],
    bearerPrefix: 'Bearer',
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: false,
    },
  },
  tiConfig: {
    baseUrl: '$`TI_BASE_HREF`',
  },
  matomoConfig: {
    scriptUrl: `${matomoBaseUrl}/matomo.js`,
    trackers: [
      {
        trackerUrl: `${matomoBaseUrl}/matomo.php`,
        siteId: $`SITE_ID`,
      },
    ],
    requireConsent: true,
    routeTracking: {
      enable: true,
    },
  },
  matomoTagManagerConfig: {
    containerId: '$`CONTAINER_ID`',
    host: `${matomoBaseUrl}`,
  },
  svsAppEntrypointUrl: '$`SVS_APP_ENTRYPOINT_URL`',
  svsTIUrl: '$`SVS_TI_URL`',
  cmsRedirectUrl: '$`CMS_REDIRECT_URL`',
  loginWithKvnUrl: '$`LOGIN_WITH_KVN_URL`',
  appHub: $`APP_HUB`,
  enableContractsOverview: $`ENABLE_CONTRACTS_OVERVIEW`,
  enableFamilyDoctorCenterCare: $`ENABLE_FAMILY_DOCTOR_CENTER_CARE`,
  enableNotifications: $`ENABLE_NOTIFICATIONS`,
  enableFeedback: $`ENABLE_FEEDBACK`,
  enableContactRequest: $`ENABLE_CONTACT_REQUEST`,
  enableHZVReports: $`ENABLE_HZV_REPORTS`,
  enableArzneimittelReports: $`ENABLE_ARZNEIMITTEL_REPORTS`,
  enableHeilmittelReports: $`ENABLE_HEILMITTEL_REPORTS`,
  checkTI: $`CHECK_TI`,
  enableSendDmpMessageToAOK: $`ENABLE_SEND_DMP_MESSAGE_TO_AOK`,
  enableDmpCaseManagement: $`ENABLE_DMP_CASE_MANAGEMENT`,
  enableBenefits: $`ENABLE_BENEFITS`,
  enableDisturbanceMessage: $`ENABLE_DISTURBANCE_MESSAGE`,
  cardReaderApiUrl: '$`CARD_READER_API_URL`',
};
