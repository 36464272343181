import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AokOrg,
  AokRegistrationOrgData,
  AokToastService,
  AokUser,
  AokUserClient,
  AokUserRegistration,
  AokUserRegistrationClient,
  AokUserRegistrationStatus,
  AokUserSalutationIntlPipe,
  DialogBase,
  DialogRef,
  DropdownSchema,
  email,
  explicitlyRequiredTrue,
  FormErrorDetectorRef,
  isEmailInUse,
  KnownUserSalutation,
  KnownUserType,
  name,
  phoneNumber,
  PopoverDirection,
  sanitizePhoneNumber,
} from '@aok/common';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogResult, MfaDialogAction, MfaDialogFormData, MfaDialogMode, ProfileAssistant } from '../../../schema';
import { DialogManagerService } from '../../../services';
import { FormErrorsBannerUtil } from '../../../utils/errors/form-errors-banner.util';

@Component({
  selector: 'aok-cockpit-mfa-dialog',
  templateUrl: './mfa-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./mfa-dialog.component.scss'],
})
export class AokMfaDialog extends FormErrorsBannerUtil implements OnInit, DialogBase<DialogResult<MfaDialogAction>> {
  @Input() public user: AokUser;
  @Input() public org: AokOrg;
  @Input() public schema: ProfileAssistant;

  public popoverDirection: PopoverDirection = PopoverDirection.TopLeft;
  public form: UntypedFormGroup;
  public formLabels = {
    salutation: 'Anrede',
    title: 'Titel',
    firstname: 'Vorname',
    lastname: 'Nachname',
    mailAddress: 'E-Mail',
    phoneNumber: 'Telefon',
    consentDeclaration: {
      label: `Hiermit bestätige ich, dass mein Mitarbeiter der Verarbeitung der
        personenbezogenen Daten für die Registrierung zugestimmt hat. Außerdem bestätige ich, dass mein Mitarbeiter
        zugestimmt hat, dass ich die Daten, wenn erforderlich, bearbeiten darf.*`,
      alternative: 'Einverständniserklärung',
    },
  };
  public salutationOptions: DropdownSchema[] = Object.values(KnownUserSalutation)
    .filter((salutation) => salutation !== KnownUserSalutation.None)
    .map((salutation) => {
      return {
        label: this.userSalutationPipe.transform(salutation),
        value: salutation,
      } as unknown as DropdownSchema;
    });
  public displayRemovePracticeStaffButton: boolean;
  public msgLabel: string;
  public hintPopoverMail: string;
  public labelSubmitButton: string;
  private msgLabelNew = 'Praxismitarbeiter einladen';
  private msgLabelManipulate = 'Praxismitarbeiter bearbeiten';
  private hintPopoverMailNew =
    'Die E-Mail wird für das Nutzerprofil des Praxismitarbeiters verwendet. Jeder ' +
    'Praxismitarbeiter benötigt daher eine eigene E-Mail.';
  private hintPopoverMailManipulate =
    'Die E-Mail wird für das Nutzerprofil des Praxismitarbeiters verwendet. Sie ist ' +
    'nur vom Nutzer selbst editierbar.';
  private labelSubmitButtonNew = 'Praxismitarbeiter einladen';
  private labelSubmitButtonManipulate = 'Daten speichern';
  private mode: MfaDialogMode;

  constructor(
    public readonly dialogRef: DialogRef<DialogResult<MfaDialogAction>>,
    protected userClient: AokUserClient,
    protected errorDetectorRef: FormErrorDetectorRef,
    protected registrationClient: AokUserRegistrationClient,
    private dialogService: DialogManagerService,
    private userSalutationPipe: AokUserSalutationIntlPipe,
    private toastService: AokToastService
  ) {
    super();
    this.msgLabel = this.msgLabelNew;
    this.hintPopoverMail = this.hintPopoverMailNew;
    this.labelSubmitButton = this.labelSubmitButtonNew;
  }

  ngOnInit(): void {
    this.adjustFormBasedOnSchema();
  }

  public isInvited(): boolean {
    return (this.schema as AokUserRegistration)?.registrationStatus === AokUserRegistrationStatus.Invited;
  }

  public isInviteMode(): boolean {
    return this.mode === MfaDialogMode.INVITE;
  }

  public deleteInvitation(): void {
    this.dialogService
      .deletePMInvite(this.schema as AokUserRegistration)
      .pipe(
        catchError(() => {
          this.dialogRef.dispose({
            action: MfaDialogAction.DELETE,
            success: false,
          });

          return EMPTY;
        })
      )
      .subscribe(() => {
        this.dialogRef.dispose({
          action: MfaDialogAction.DELETE,
          success: true,
        });
      });
  }

  public removePracticeEmployee(): void {
    this.dialogService.openRemovePMDialog(this.schema).subscribe((removed) => {
      if (removed) {
        this.dialogRef.dispose({
          action: MfaDialogAction.REMOVE_EMPLOYEE,
          success: true,
        });
      }
    });
  }

  public submit(): void {
    if (this.form.invalid) {
      this.handleErrors({ form: this.form, formControlName: 'mailAddress' });
    } else {
      this.errorIds = [];
      const { ...body } = this.form.value;
      const { salutation, title, firstname, lastname, mailAddress, phoneNumber } = body;
      if (this.mode === MfaDialogMode.EDIT_USER) {
        this.userClient
          .update(this.schema.id, {
            salutation: salutation?.value || salutation,
            firstName: firstname,
            lastName: lastname,
            email: this.form.get('mailAddress').value,
            mobilePhone: sanitizePhoneNumber(phoneNumber),
            userType: KnownUserType.Assistant,
            practitionerRequest: {
              title,
              // TODO we should not send data that cannot change
              organizationsIds: (this.schema as AokUser).practitionerResource.organizationsIds,
            },
          })
          .pipe(
            catchError(() => {
              this.toastService.createSupportErrorToast(
                'Technischer Fehler',
                'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
              );
              return EMPTY;
            })
          )
          .subscribe(() => {
            this.dialogRef.dispose({
              action: MfaDialogAction.EDIT,
              success: true,
            });
          });
      } else if (this.mode === MfaDialogMode.EDIT_INVITE) {
        this.registrationClient
          .updateRegistration({
            id: this.schema.id,
            userData: {
              firstName: firstname,
              lastName: lastname,
              title,
              email: this.form.get('mailAddress').value,
              mobilePhone: sanitizePhoneNumber(phoneNumber),
              salutation: salutation?.value || salutation,
              userType: KnownUserType.Assistant,
            },
            // TODO is this needed to be resent?
            organizationData: {
              bsnr: this.org.bsnr,
              organizationId: this.org.id,
            } as AokRegistrationOrgData,
          })
          .pipe(
            catchError(() => {
              this.toastService.createSupportErrorToast(
                'Technischer Fehler',
                'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
              );
              return EMPTY;
            })
          )
          .subscribe(() => {
            this.dialogRef.dispose({
              action: MfaDialogAction.EDIT,
              success: true,
            });
          });
      } else {
        this.registrationClient
          .createAssistant({
            userData: {
              salutation: salutation?.value || salutation,
              firstName: firstname,
              lastName: lastname,
              title,
              email: mailAddress,
              mobilePhone: phoneNumber,
              userType: KnownUserType.Assistant,
            },
            organizationData: {
              bsnr: this.org.bsnr,
            },
          })
          .pipe(
            catchError(() => {
              this.toastService.createSupportErrorToast(
                'Technischer Fehler',
                'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
              );
              return EMPTY;
            })
          )
          .subscribe(() => {
            this.dialogRef.dispose({
              action: MfaDialogAction.INVITED,
              success: true,
            });
          });
      }
    }
  }

  private adjustFormBasedOnSchema(): void {
    const status = (this.schema as AokUserRegistration)?.registrationStatus;

    if (this.schema) {
      this.displayRemovePracticeStaffButton = !status || status === AokUserRegistrationStatus.CardCreation;
      this.handleMfaEdit();
    } else {
      this.displayRemovePracticeStaffButton = false;
      this.handleMfaInvite();
    }
  }

  private handleMfaEdit(): void {
    this.form = this.createEditForm();

    // sets the labels and values
    this.hintPopoverMail = this.hintPopoverMailManipulate;
    this.msgLabel = this.msgLabelManipulate;
    this.labelSubmitButton = this.labelSubmitButtonManipulate;

    let formData: MfaDialogFormData;

    if ('userData' in this.schema) {
      this.mode = MfaDialogMode.EDIT_INVITE;
      const {
        userData: { firstName, lastName, mobilePhone, salutation, title, email },
      } = this.schema;
      formData = {
        salutation,
        title,
        firstname: firstName,
        lastname: lastName,
        mailAddress: email,
        phoneNumber: mobilePhone,
      };
    } else {
      this.mode = MfaDialogMode.EDIT_USER;
      const { salutation, practitionerResource, practitionerRequest, firstName, lastName, mobilePhone, email } =
        this.schema;
      formData = {
        salutation,
        title: (practitionerRequest || practitionerResource)?.title,
        firstname: firstName,
        lastname: lastName,
        mailAddress: email,
        phoneNumber: mobilePhone,
      };
    }

    this.form.patchValue(formData);
  }

  private handleMfaInvite(): void {
    this.mode = MfaDialogMode.INVITE;
    this.form = this.createInviteForm();

    // sets the labels
    this.hintPopoverMail = this.hintPopoverMailNew;
    this.msgLabel = this.msgLabelNew;
    this.labelSubmitButton = this.labelSubmitButtonNew;
  }

  private createInviteForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      salutation: new UntypedFormControl(null, Validators.required),
      title: new UntypedFormControl(),
      firstname: new UntypedFormControl(null, [Validators.required, name('firstname')]),
      lastname: new UntypedFormControl(null, [Validators.required, name('lastname')]),
      mailAddress: new UntypedFormControl(null, {
        validators: [Validators.required, email],
        asyncValidators: isEmailInUse(this.userClient),
        updateOn: 'blur',
      }),
      phoneNumber: new UntypedFormControl(null, phoneNumber),
      consentDeclaration: new UntypedFormControl(false, explicitlyRequiredTrue),
    });
  }

  private createEditForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      salutation: new UntypedFormControl(null, Validators.required),
      title: new UntypedFormControl(),
      firstname: new UntypedFormControl(null, [Validators.required, name('firstname')]),
      lastname: new UntypedFormControl(null, [Validators.required, name('lastname')]),
      mailAddress: new UntypedFormControl(
        { value: null, disabled: true },
        {
          validators: [Validators.required, email],
          asyncValidators: isEmailInUse(this.userClient),
          updateOn: 'blur',
        }
      ),
      phoneNumber: new UntypedFormControl(null, phoneNumber),
    });
  }
}
