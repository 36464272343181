import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import {
  AokKvnUserRegistration,
  AokOrgClient,
  AokSpecializationState,
  AokUserRegistration,
  DialogBase,
  DialogRef,
  DoctorType,
  DropdownSchema,
  getDoctorType,
  getOrgTypeText,
  getSalutationText,
  getSpecializationDescription,
  KnownUserSalutation,
  NotificationBannerLink,
  PopoverDirection,
  removeEmptySchemaProperties,
  sanitizePhoneNumber,
  scrollToElement,
  scrollToTop,
} from '@aok/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorState } from '../../../schema';
import { AokInputFieldComponent } from '../../cdk';
import { RadioOptions } from '../../radio-button/radio.component';
import { AokStepComponent } from '../../stepper/step.component';
import { AokStepperComponent, StepperNavigationMode } from '../../stepper/stepper.component';

@Component({
  selector: 'aok-base-registration',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export abstract class AokBaseRegistrationDialog
  implements OnInit, OnDestroy, DialogBase<Partial<AokUserRegistration | AokKvnUserRegistration>>
{
  public readonly doctorTypes: RadioOptions[] = [
    {
      label: getDoctorType(DoctorType.OWNER),
      value: DoctorType.OWNER,
      popover: `<p>Bitte wählen Sie, in welcher Form Sie aktuell in einer Praxis tätig sind. Die Eingabe der Praxisdaten erfolgt im nächsten Schritt.</p>`,
    },
    { label: getDoctorType(DoctorType.EMPLOYED), value: DoctorType.EMPLOYED },
  ];
  public readonly salutationOptions: DropdownSchema[] = [
    { label: getSalutationText(KnownUserSalutation.Mr), value: KnownUserSalutation.Mr },
    { label: getSalutationText(KnownUserSalutation.Mrs), value: KnownUserSalutation.Mrs },
  ];
  public orgTypeOptions: DropdownSchema[];
  public specializationOptions = this.specializationState.snapshot;
  public formGroup: FormGroup;
  public readonly errorStateEnum = ErrorState;
  public readonly NAV_MODE = StepperNavigationMode;
  public readonly POPOVER_DIRECTION = PopoverDirection;
  public isErrorBannerEnabled = false;
  public errorState: ErrorState = ErrorState.default;
  public bannerLinks: Array<NotificationBannerLink> = [];
  protected stepper: AokStepperComponent;
  protected readonly destroys = new Subject<void>();

  public get specializationControlValue(): string {
    return this.formGroup.get('userData.specialization').value;
  }

  constructor(
    public readonly dialogRef: DialogRef<Partial<AokUserRegistration | AokKvnUserRegistration>>,
    protected specializationState: AokSpecializationState,
    protected cd: ChangeDetectorRef,
    protected orgClient: AokOrgClient
  ) {}

  public ngOnInit(): void {
    this.orgClient.listTypes().subscribe((orgTypes) => {
      this.orgTypeOptions = orgTypes.map((type) => {
        return { label: getOrgTypeText(type), value: type };
      });
    });
  }

  ngOnDestroy(): void {
    this.destroys.next();
    this.destroys.complete();
  }

  public displaySpecialization(): boolean {
    return this.specializationControlValue && !this.formGroup.get('userData.lanr').invalid;
  }

  public closeDialog(): void {
    const registration = this.buildRegistration(true);
    this.dialogRef.dispose(registration);
  }

  public goToStep(index: number): void {
    this.changeStep(this.stepper.steps.get(index));
  }

  protected setupLanrSpecializationHandler(): void {
    const lanrControl = this.formGroup.get('userData.lanr');
    this.setSpecialization(lanrControl);
    lanrControl.valueChanges.pipe(takeUntil(this.destroys)).subscribe(() => {
      this.setSpecialization(lanrControl);
    });
  }

  protected setSpecialization(lanrControl: AbstractControl): void {
    const specializationControl = this.formGroup.get('userData.specialization');
    if (lanrControl.invalid) {
      specializationControl?.setValue(null);
    } else {
      const specialization = getSpecializationDescription(lanrControl.value, this.specializationOptions);
      specializationControl?.setValue(specialization);
    }

    this.cd.markForCheck();
  }

  /**
   * Build registration object from form
   * @param interim - check whether we need final object for submitting or saving intermediary object
   */
  protected buildRegistration(interim = false): Partial<AokUserRegistration | AokKvnUserRegistration> {
    const registration = removeEmptySchemaProperties<AokUserRegistration | AokKvnUserRegistration>(
      this.formGroup.getRawValue()
    );
    if (!interim) {
      registration.userData.lanr = registration.userData.lanr.substring(0, 7);
    }

    if (registration.userData?.mobilePhone) {
      registration.userData.mobilePhone = sanitizePhoneNumber(registration.userData.mobilePhone);
    }

    return registration;
  }

  protected changeStep(newStep: AokStepComponent): void {
    this.stepper.activateStep(newStep);
    this.isErrorBannerEnabled = false;
  }

  protected validate(bannerLinks: NotificationBannerLink[], byValueChanges?: boolean): boolean {
    if (bannerLinks.length === 0) {
      this.errorState = ErrorState.default;
      // once the errors got resolved, we only want to show the banner after another submit call
      if (this.isErrorBannerEnabled) {
        this.isErrorBannerEnabled = false;
      }
    } else {
      this.errorState = bannerLinks.length === 1 ? ErrorState.error : ErrorState.multiError;
      if (!byValueChanges) {
        this.isErrorBannerEnabled = true;
        scrollToTop();
      }
    }
    return bannerLinks.length === 0;
  }

  protected getElementLinksFromInvalids(
    inputDataFields: QueryList<AokInputFieldComponent> | AokInputFieldComponent[],
    formGroup: FormGroup
  ): NotificationBannerLink[] {
    const bannerFields: Array<NotificationBannerLink> = [];
    inputDataFields?.forEach((element) => {
      this.collectErrorsForAokNotificationBannerLink(bannerFields, element, formGroup);
    });
    return bannerFields;
  }

  /**
   * checks if for the given input element errors exist in the corresponding FormGroup and adds them to the bannerFields
   **/
  private collectErrorsForAokNotificationBannerLink(
    bannerFields: Array<NotificationBannerLink>,
    input: AokInputFieldComponent,
    formElement: AbstractControl,
    formControlName?: string
  ): void {
    if (formElement instanceof FormGroup || formElement instanceof FormArray) {
      Object.keys(formElement.controls).forEach((key) => {
        this.collectErrorsForAokNotificationBannerLink(bannerFields, input, formElement.get(key), key);
      });
    } else if (formElement.errors && formControlName === input.elementId) {
      bannerFields.push({
        label: input.label,
        clickHandler: () => {
          scrollToElement(input.contentWrapper.nativeElement);
        },
      });
    }
  }
}
