import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import {
  A11yUtilsModule,
  AokActiveAccountModule,
  AokFeedbackCategoriesModule,
  AokFullAddressPipe,
  AokHttpErrorInterceptorModule,
  AokHttpParamInterceptorModule,
  AokLanr9Pipe,
  AokLoaderInterceptorModule,
  AokLoadingStateModule,
  AokOrgTypeIntlPipe,
  AokRingLoaderComponent,
  AokRuntimeProfileModule,
  AokSpecializationsModule,
  AokSvgIconComponent,
  AokTitleStrategyModule,
  DialogOverlayModule,
  ENVIRONMENT,
  KEYCLOAK_OPTIONS,
  KnownRoles,
  MatomoInjectorService,
  NavigationModule,
  RequiredTasksModule,
  RoleRedirectTaskService,
} from '@aok/common';
import {
  AccordionService,
  AOK_CONTACT_PROVIDER,
  AokA11yLinksModule,
  AokBreadcrumbsModule,
  AokCockpitAddBSNRAsMfaDialogModule,
  AokCockpitAddBSNRDialogModule,
  AokCockpitChangeEmailDialogModule,
  AokConsentDialogModule,
  AokCookieDialogModule,
  AokDefaultErrorMessageModule,
  AokHttpErrorDialogComponent,
  AokHttpErrorDialogModule,
  AokMfaDialogModule,
  AokMfaRequestDialogModule,
  AokNavModule,
  AokNotificationBellModule,
  AokNotificationSidebarModule,
  AokOrgContextSelectModule,
  AokRemovePracticeStaffDialogModule,
  AokStaticArticleModule,
  AokToastComponent,
  APP_A11Y_LINKS_PROVIDER,
  APP_PAGE_FOOTER_PROVIDER,
  APP_PAGE_HEADER_PROVIDER,
  BreadcrumbsService,
  ConsentChangesTaskService,
  ContextSelectorTaskService,
  FeedbackPopoverModule,
  HeaderModule,
  KvnErrorRedirectTaskService,
  NOTIFICATIONS_ENABLED,
  NOTIFICATIONS_POLL,
  OpenDialogTaskService,
  RedirectToTaskService,
  TIOverlayComponent,
} from '@aok/components';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MatomoModule } from 'ngx-matomo';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { APP_ROOT_ROUTES } from './app.routes';
import { AOK_COCKPIT_ACCESS_INIT_PROVIDER } from './bootstrap/cockpit-access.listener';
import { AOK_FEEDBACK_AVAILABLE_INIT_PROVIDER } from './bootstrap/feedback-available.listener';
import { AOK_FOOTER_LAYOUT_INIT_PROVIDER } from './bootstrap/footer-layout.listener';
import { AOK_HEADER_LAYOUT_INIT_PROVIDER } from './bootstrap/header-layout.listener';
import { AOK_INFO_BANNER_PROVIDER } from './bootstrap/info-banner.listener';
import { AppConfig } from './config/app.config';
import { PendingExpirationSessionModule } from './interceptors/pending-expiration-session/pending-expiration-session.module';
import { TIInterceptor } from './interceptors/ti.interceptor';
import { KvnErrorComponent } from './pages/kvn-error/kvn-error.component';
import { AokNotAccessiblePage } from './pages/not-accessible/not-accessible.component';
import { TIErrorComponent } from './pages/ti-error/ti-error.component';
import { APP_ERROR_MSG_PROVIDER } from './providers/error-msg.provider';
import { APP_ICON_IMPORT_PROVIDER } from './providers/icons.provider';
import { APP_SITEMAP_PROVIDER } from './providers/sitemap.provider';
import { AokQueryParamStrategy } from './services/query-param-strategy.service';

const globalPipes = [AokFullAddressPipe, AokOrgTypeIntlPipe, AokLanr9Pipe];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    DialogOverlayModule,
    BrowserAnimationsModule,
    AokRuntimeProfileModule.forRoot({ environmentId: environment.id }),
    AokFeedbackCategoriesModule.forRoot(),
    AokLoadingStateModule.forRoot({
      overlayStrategy: 'onHttpEvent',
      indicator: AokRingLoaderComponent,
    }),
    KeycloakAngularModule,
    RouterModule.forRoot(APP_ROOT_ROUTES, {
      onSameUrlNavigation: 'reload',
    }),
    RequiredTasksModule.forRoot([
      {
        type: RoleRedirectTaskService,
        deps: [Router, KeycloakService],
        config: [
          {
            redirect: 'user',
            to: environment.cmsRedirectUrl,
          },
        ],
      },
      KvnErrorRedirectTaskService,
      ConsentChangesTaskService,
      {
        type: RoleRedirectTaskService,
        deps: [Router, KeycloakService],
        config: [
          {
            redirect: [KnownRoles.PendingHealthcareProfessional, KnownRoles.PendingAssistant],
            to: '/registrierungsanfrage',
          },
        ],
      },
      ContextSelectorTaskService,
      RedirectToTaskService,
      OpenDialogTaskService,
    ]),
    AokTitleStrategyModule.withConfig({ titlePrefix: 'AOK-Arztportal' }),
    AokActiveAccountModule.forRoot(),
    AokSpecializationsModule.forRoot(),
    AokCookieDialogModule.forRoot(),
    AokConsentDialogModule.forRoot(),
    AokStaticArticleModule.forRoot({
      routeDataKey: 'article',
      routeParamKey: 'articleIdOrKey',
      staticSignLanguageVideos: [
        {
          src: 'https://mediathek.aok.de/p/102/sp/10200/serveFlavor/entryId/0_u2yxl2oi/v/2/ev/7/flavorId/0_hzqmu4yl/forceproxy/true/name/a.mp4',
          description:
            'In diesem Video finden Sie Informationen zur Barrierefreiheit des AOK-Arztportals in Deutscher Gebärdensprache (DGS).',
        },
        {
          src: 'https://mediathek.aok.de/p/102/sp/10200/serveFlavor/entryId/0_zm0vd6mv/v/2/ev/7/flavorId/0_yp34jwsu/forceproxy/true/name/a.mp4',
          description:
            'In diesem Video finden Sie Informationen zur Navigation des AOK-Arztportals in Deutscher Gebärdensprache (DGS).',
        },
        {
          src: 'https://mediathek.aok.de/p/102/sp/10200/serveFlavor/entryId/0_wd37vbeo/v/2/ev/7/flavorId/0_we5q7rmi/forceproxy/true/name/a.mp4',
          description:
            'In diesem Video finden Sie Informationen zum Inhalt des AOK-Arztportals in Deutscher Gebärdensprache (DGS).',
        },
      ],
    }),
    AokHttpErrorDialogModule,
    AokLoaderInterceptorModule.forRoot(),
    PendingExpirationSessionModule.forRoot(),
    AokHttpErrorInterceptorModule.forRoot({ dialogComponent: AokHttpErrorDialogComponent }),
    AokHttpParamInterceptorModule.forRoot(),
    AokDefaultErrorMessageModule.forRoot(),
    NavigationModule,
    AokA11yLinksModule,
    AokOrgContextSelectModule,
    AokBreadcrumbsModule,
    AokNavModule,
    AokNotificationBellModule,
    ToastrModule.forRoot({
      toastComponent: AokToastComponent,
      timeOut: 7000,
      extendedTimeOut: 7000,
      toastClass: '',
      messageClass: '',
      titleClass: '',
      tapToDismiss: false,
    }),
    A11yUtilsModule,
    MatomoModule.forRoot(environment.matomoConfig),
    AokNotificationSidebarModule,
    AokSvgIconComponent,
    HeaderModule,
    AokMfaDialogModule,
    AokMfaRequestDialogModule,
    AokCockpitAddBSNRDialogModule,
    AokCockpitChangeEmailDialogModule,
    AokCockpitAddBSNRAsMfaDialogModule,
    AokRemovePracticeStaffDialogModule,
    FeedbackPopoverModule,
    TIErrorComponent,
    TIOverlayComponent,
    KvnErrorComponent,
    PlatformModule,
    AokNotAccessiblePage,
  ],
  providers: [
    AccordionService,
    BreadcrumbsService,
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de',
    },
    {
      provide: KEYCLOAK_OPTIONS,
      useValue: environment.keycloak,
    },
    {
      provide: NOTIFICATIONS_ENABLED,
      useValue: environment.enableNotifications,
    },
    {
      provide: NOTIFICATIONS_POLL,
      useValue: AppConfig.notificationPoll,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TIInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: AokQueryParamStrategy,
    },

    AOK_COCKPIT_ACCESS_INIT_PROVIDER,
    AOK_FEEDBACK_AVAILABLE_INIT_PROVIDER,
    AOK_HEADER_LAYOUT_INIT_PROVIDER,
    AOK_FOOTER_LAYOUT_INIT_PROVIDER,
    APP_PAGE_HEADER_PROVIDER,
    APP_A11Y_LINKS_PROVIDER,
    APP_PAGE_FOOTER_PROVIDER,
    AOK_CONTACT_PROVIDER,

    APP_ICON_IMPORT_PROVIDER,
    APP_SITEMAP_PROVIDER,
    APP_ERROR_MSG_PROVIDER,
    AOK_INFO_BANNER_PROVIDER,
    ...globalPipes,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // needed for instantiation
  constructor(protected matomoInjector: MatomoInjectorService) {}
}
