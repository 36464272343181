<dialog-layout
  [fullscreen]="true"
  [headerText]="'Registrierung vervollständigen'"
  [isClosable]="true"
  [autoClose]="false"
  cdkTrapFocus
  (dispose)="closeDialog()"
>
  <form [formGroup]="formGroup">
    <aok-stepper
      #stepper
      [navMode]="NAV_MODE.FREE_SEQUENTIAL"
      (stepChange)="handleStepChange($event)"
      (stepChanged)="handleStepChanged($event)"
      (submitted)="submit()"
    >
      <div *ngIf="isErrorBannerEnabled && errorState" id="errorMessages" class="userMessages">
        <ng-container [ngSwitch]="errorState">
          <aok-notification-banner
            *ngSwitchCase="errorStateEnum.multiError"
            id="error_validation_multi"
            accent="bad"
            bannerHeadline="Es sind mehrere Fehler aufgetreten."
            bannerLabel="Bitte überprüfen Sie Ihre Eingabe."
            [bannerLinks]="bannerLinks"
          >
          </aok-notification-banner>

          <aok-notification-banner
            *ngSwitchCase="errorStateEnum.error"
            id="error_validation_one"
            accent="bad"
            bannerHeadline="Es ist ein Fehler aufgetreten"
            bannerLabel="Bitte überprüfen Sie Ihre Eingabe."
            [bannerLinks]="bannerLinks"
          >
          </aok-notification-banner>
        </ng-container>
      </div>

      <aok-step
        #personalDataStep
        [title]="'Persönliche Daten'"
        [allowLinebreakInTitle]="false"
        [iconName]="'0284-profile'"
        aokFocusFirstChild
      >
        <div formGroupName="userData" id="personalDataForm" class="grid-10 lg:grid-8">
          <p class="mandatory-hint required-label tw-col-1-9">* Pflichtfeld</p>
          <h5 class="tw-col-1-11 lg:tw-col-1-9">Persönliche Daten</h5>

          <aok-input-field #userData label="Anrede" required="true" class="tw-col-1-4">
            <aok-dropdown
              #dropdown
              id="salutation"
              [control]="formGroup.get('userData.salutation')"
              [options]="salutationOptions"
            ></aok-dropdown>
          </aok-input-field>

          <aok-input-field #userData label="Titel" class="tw-col-4-8 lg:tw-col-4-7">
            <input id="title" formControlName="title" aokTrim />
          </aok-input-field>

          <aok-input-field #userData label="Vorname" required="true" class="tw-col-1-8 lg:tw-col-1-7">
            <input id="firstName" formControlName="firstName" aokTrim />
          </aok-input-field>

          <aok-input-field #userData label="Nachname" required="true" class="tw-col-1-8 lg:tw-col-1-7">
            <input id="lastName" formControlName="lastName" aokTrim />
          </aok-input-field>

          <div class="tw-col-1-11">
            <div class="radio-with-popover">
              <aok-input-field #userData label="Rechtlicher Status" required="true">
                <aok-radio id="doctorType" [options]="doctorTypes" formControlName="doctorType"></aok-radio>
              </aok-input-field>
            </div>
          </div>

          <div class="form-item-with-popover tw-col-1-8 lg:tw-col-1-7">
            <aok-input-field #userData label="LANR" required="true">
              <input id="lanr" formControlName="lanr" />
            </aok-input-field>
            <aok-popover
              width="384"
              id="lanrPopoverIcon"
              [direction]="POPOVER_DIRECTION.RightTop"
              [xaxis]="3"
              [yaxis]="-2"
            >
              <aok-svg-icon [name]="'question-circle_filled'" size="20" aok-anchor></aok-svg-icon>
              <p id="lanrPopoverContent">Bitte geben Sie hier Ihre neunstellige, lebenslange Arztnummer (LANR) ein.</p>
            </aok-popover>
          </div>

          <aok-notification-banner
            *ngIf="displaySpecialization()"
            class="specialization tw-col-1-8 lg:tw-col-1-7"
            bannerHeadline="Fachgruppe"
            iconName="info-circle"
            [bannerLabel]="specializationControlValue"
          >
          </aok-notification-banner>

          <h5 class="distance-h5 tw-col-1-11 lg:tw-col-1-9">Kontaktdaten</h5>

          <aok-input-field #userData label="Telefonnummer" class="tw-col-1-8 lg:tw-col-1-7">
            <input id="mobilePhone" formControlName="mobilePhone" aokTrim />
          </aok-input-field>

          <h5 class="distance-h5 tw-col-1-11 lg:tw-col-1-9">Zwei-Faktor-Authentifizierung</h5>

          <p class="tw-mt-6 tw-col-1-13">
            {{ secondFactorAuthHint }}
          </p>

          <div class="tw-col-1-13 tw-mt-7">
            <aok-second-factor-auth-options
              [form]="formGroup.get('userData')"
              [formLabels]="formLabels"
              [isCardOrderingEnabled]="isCardOrderingEnabled"
            >
            </aok-second-factor-auth-options>
          </div>
        </div>
      </aok-step>

      <aok-step #practiceDataStep [title]="'Praxisdaten'" [iconName]="'0000-hospital'" aokFocusFirstChild>
        <div formGroupName="organizationData" id="practiceDataForm" class="grid-10 lg:grid-8">
          <p class="mandatory-hint required-label tw-col-1-9">* Pflichtfeld</p>
          <h5 class="tw-col-1-11 lg:tw-col-1-9">Praxisdaten</h5>

          <div class="form-item-with-popover tw-col-1-8 lg:tw-col-1-7">
            <aok-input-field #orgData label="Betriebsstättennummer" required="true">
              <input id="bsnr" #bsnrInput formControlName="bsnr" />
            </aok-input-field>

            <aok-popover
              width="384"
              id="bsnrPopoverIcon"
              [direction]="POPOVER_DIRECTION.RightTop"
              [xaxis]="3"
              [yaxis]="-2"
            >
              <aok-svg-icon [name]="'question-circle_filled'" size="20" aok-anchor></aok-svg-icon>
              <p id="bsnrPopoverContent">
                Sie sind für mehrere Betriebsstätten tätig? Geben Sie hier zunächst eine Betriebsstätte an. Sie können
                nach erfolgreicher Registrierung weitere Betriebsstätten hinzufügen.
              </p>
            </aok-popover>
          </div>

          <aok-input-field #orgData label="Art der Praxis" required="true" class="tw-col-1-8 lg:tw-col-1-7">
            <aok-dropdown
              id="organizationType"
              [control]="formGroup.get('organizationData.organizationType')"
              [options]="orgTypeOptions"
            ></aok-dropdown>
          </aok-input-field>

          <div class="form-item-with-popover tw-col-1-8 lg:tw-col-1-7">
            <aok-input-field #orgData label="Name der Praxis" required="true">
              <input id="organizationName" formControlName="organizationName" aokTrim />
            </aok-input-field>

            <aok-popover
              width="384"
              id="praxisPopoveIcon"
              [direction]="POPOVER_DIRECTION.RightTop"
              [xaxis]="3"
              [yaxis]="-2"
            >
              <aok-svg-icon [name]="'question-circle_filled'" size="20" aok-anchor></aok-svg-icon>
              <p id="praxisPopoverContent">
                Wenn es keinen expliziten Praxisnamen gibt, geben Sie bitte den Namen des Arztes ein.
              </p>
            </aok-popover>
          </div>

          <h5 class="distance-h5 tw-col-1-11 lg:tw-col-1-9">Praxisanschrift</h5>

          <ng-container formGroupName="address">
            <aok-input-field #orgData label="Straße" required="true" class="tw-col-1-7 lg:tw-col-1-6">
              <input id="streetName" formControlName="streetName" aokTrim />
            </aok-input-field>

            <aok-input-field #orgData label="Hausnummer" required="true" class="tw-col-7-11 lg:tw-col-6-9">
              <input id="streetNumber" formControlName="streetNumber" />
            </aok-input-field>

            <aok-input-field #orgData label="PLZ" required="true" class="tw-col-1-5 lg:tw-col-1-4">
              <input id="zipCode" formControlName="zipCode" />
            </aok-input-field>

            <aok-input-field #orgData label="Ort" required="true" class="tw-col-5-11 lg:tw-col-4-9">
              <input id="location" formControlName="location" aokTrim />
            </aok-input-field>
          </ng-container>
        </div>
      </aok-step>

      <aok-step [title]="'Zusammenfassung'" [iconName]="'0000-checklist'" aokFocusFirstChild>
        <div class="summaryData grid-10 lg:grid-8">
          <div class="tw-col-1-11 lg:tw-col-1-9 tw-flex tw-justify-between tw-items-center tw-pb-7">
            <h5>Persönliche Daten</h5>

            <a
              class="aok-link-1"
              aokFocusable
              (keyup.enter)="stepper.select(personalDataStep)"
              (click)="stepper.select(personalDataStep)"
              >Ändern</a
            >
          </div>

          <div class="divider tw-col-1-11 lg:tw-col-1-9"></div>
          <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Name</p>
          <p id="name" class="tw-col-5-11 lg:tw-col-4-9">
            {{ formGroup.get('userData').get('salutation').value | aokUserSalutation : ['NONE'] }}
            {{ formGroup.get('userData').get('salutation').value === 'NONE' ? '' : ' ' }}
            {{
              formGroup.get('userData').value
                | aokFullName
                  : ' TT FF LL'
                  : formGroup.get('userData').value?.practitionerResource ||
                      formGroup.get('userData').value?.practitionerRequest
            }}
          </p>
          <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Rechtlicher Status</p>
          <p id="status" class="tw-col-5-11 lg:tw-col-4-9">
            {{ formGroup.get('userData').get('doctorType').value | aokDoctorType }}
          </p>
          <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">LANR</p>
          <p id="LANR" class="tw-col-5-11 lg:tw-col-4-9">
            {{ formGroup.get('userData').get('lanr').value }}
          </p>
          <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Fachgruppenschlüssel</p>
          <p id="departmentKey" class="tw-col-5-11 lg:tw-col-4-9">
            {{ specializationControlValue }}
          </p>
          <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Telefonnummer</p>
          <p id="phone" class="tw-col-5-11 lg:tw-col-4-9">
            {{ formGroup.get('userData').get('mobilePhone').value }}
          </p>
          <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Authentifizierungsart</p>
          <p id="authenticationType" class="tw-col-5-11 lg:tw-col-4-9">
            {{ formGroup.get('userData').get('authOption').value | aokAuthOption }}
          </p>

          <div class="tw-col-1-11 lg:tw-col-1-9 tw-flex tw-justify-between tw-items-center tw-pb-7">
            <h5>Praxisdaten</h5>
            <a
              class="aok-link-1"
              aokFocusable
              (keyup.enter)="stepper.select(practiceDataStep)"
              (click)="stepper.select(practiceDataStep)"
              >Ändern</a
            >
          </div>
          <div class="divider tw-col-1-11 lg:tw-col-1-9"></div>
          <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Betriebsstätennummer</p>
          <p id="bsnr" class="tw-col-5-11 lg:tw-col-4-9">
            {{ formGroup.get('organizationData').get('bsnr').value }}
          </p>
          <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Art der Praxis</p>
          <p id="organizationType" class="tw-col-5-11 lg:tw-col-4-9">
            {{ formGroup.get('organizationData').get('organizationType').value | aokOrgType }}
          </p>
          <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Name der Praxis</p>
          <p id="organizationName" class="tw-col-5-11 lg:tw-col-4-9">
            {{ formGroup.get('organizationData').get('organizationName').value }}
          </p>
          <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Praxisanschrift</p>
          <p id="organizationAddress" class="tw-col-5-11 lg:tw-col-4-9">
            {{ formGroup.get('organizationData').get('address').get('streetName').value }}
            {{ formGroup.get('organizationData').get('address').get('streetNumber').value }},
            {{ formGroup.get('organizationData').get('address').get('zipCode').value }}
            {{ formGroup.get('organizationData').get('address').get('location').value }}
          </p>
        </div>
      </aok-step>
    </aok-stepper>
  </form>
</dialog-layout>
