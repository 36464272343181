import { Platform } from '@angular/cdk/platform';
import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AokBillingSheetTypeEnum,
  AokFullNamePipe,
  AokInsuranceStatus,
  AokInsuranceStatusClient,
  AokInsuranceStatusPipe,
  AokInsuree,
  AokLanr9Pipe,
  AokPopoverComponent,
  AokSpecializationState,
  AokUser,
  AokUserClient,
  ContextState,
  CurrentUserState,
  DropdownSchema,
  getSpecializationDescription,
  isAssistant,
  isDoctor,
  KnownUserType,
  lanr9,
  PopoverDirection,
  scrollToTop,
  specialization,
  TableData,
  TableTypeRow,
} from '@aok/common';

import {
  AokDropdownModule,
  AokInputFieldModule,
  AokNotificationBannerModule,
  AokRadioModule,
  AokTableModule,
  DropdownMode,
  RadioOptions,
} from '@aok/components';
import { EMPTY } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';
import { MatomoCustomVariable } from '../../../../config/matomo.config';
import { MatomoService } from '../../../../services/matomo.service';
import {
  getInsuranceDataTable,
  getPersonalResultTable,
  ServicePagesLabels,
} from '../../../../utils/service-pages.util';

@Component({
  selector: 'aok-cockpit-billing-sheet-result',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'billing-sheet-result.component.html',
  styleUrls: ['billing-sheet-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AokInputFieldModule,
    AokDropdownModule,
    AokPopoverComponent,
    AokNotificationBannerModule,
    AokTableModule,
    AokRadioModule,
    ReactiveFormsModule,
  ],
})
export class BillingSheetResultComponent implements OnChanges, OnInit, OnDestroy {
  @Input() response: AokInsuree;
  public lanrOptions: DropdownSchema[] = [];

  public resultTableData: TableData[] = [];

  public status: string;
  public showErrorMsg = false;
  public previewLoaded = false;
  public billingSheetPreview = null;

  public specialization: string;
  public specializationOptions = this.specializationState.snapshot;

  public lanrControl = new FormControl<string>(null, [
    Validators.required,
    lanr9,
    specialization(this.specializationOptions),
  ]);

  public dropdownMode = DropdownMode.ENHANCED;
  public popoverDirection = PopoverDirection.RightTop;
  public isDoctor = isDoctor;
  public isAssistant = isAssistant;

  public switchRadioOptions: RadioOptions[] = [
    {
      label: 'Muster 5 einlegen',
      value: AokBillingSheetTypeEnum.PrePrinted,
      id: 'prePrinted',
    },
    {
      label: 'Muster 5 ausdrucken',
      value: AokBillingSheetTypeEnum.FullPrint,
      id: 'fullPrint',
    },
  ];
  public radioSwitchForm = new UntypedFormControl(this.switchRadioOptions[0].value);
  public prePrinted = false;

  public resultBannerHeadline: string;
  public resultBannerHint: string;
  public resultBannerBackground: 'aok-bg-pastel-green' | 'aok-bg-sand';

  private insuranceBannerSuccess = 'Der Patient ist bei der AOK Niedersachsen versichert.';
  private insuranceBannerFail = `Der Status des angefragten Patienten ist unklar, weshalb kein Abrechnungsschein zur
    Verfügung gestellt werden kann.`;
  private insuranceBannerHint = 'Der Patient soll sich bitte direkt an die AOK wenden.';

  private invoiceDate: string;
  private patientId: string;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly client: AokInsuranceStatusClient,
    public readonly currentUser: CurrentUserState,
    private sanitizer: DomSanitizer,
    private lanrPipe: AokLanr9Pipe,
    private platform: Platform,
    private matomoService: MatomoService,
    private specializationState: AokSpecializationState,
    private contextState: ContextState,
    private userClient: AokUserClient,
    private fullNamePipe: AokFullNamePipe,
    private cd: ChangeDetectorRef
  ) {
    this.subscribeToPractitioner();
    this.handleLanrOptions();
    this.handleBillingSheetPreference();
    this.handleRadioSwitchChanges();
    setTimeout(() => this.lanrControl.updateValueAndValidity());
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.response) {
      this.resetResultBannerProps();
      this.prepareResultTableData();
    }
  }

  ngOnDestroy(): void {
    this.matomoService.deleteMatomoCustomVariable(MatomoCustomVariable.PRINTING_SHEET_TYPE);
  }

  public async printBillingSheet(): Promise<void> {
    if (this.lanrControl.valid) {
      this.client
        .getBillingSheet(
          String(this.patientId),
          this.contextState.bsnr,
          this.lanrControl.value,
          this.invoiceDate,
          this.radioSwitchForm.value
        )
        .subscribe((blob) => {
          const billingSheet = URL.createObjectURL(blob);

          setTimeout(() => {
            const pdfTab = window.open(billingSheet, '_blank');
            const isSafari = this.platform.SAFARI;
            if (pdfTab && !isSafari) {
              pdfTab.print();
            }
          });
        });
    }
  }

  public showBillingSheetInformation(): boolean {
    return this.status && this.status !== 'unklar';
  }

  public displaySpecialization(): boolean {
    // specialization name should only be displayed if the LANR got entered manually = not in the original list
    return this.lanrControl.valid && !this.lanrOptions.map((option) => option.value).includes(this.lanrControl.value);
  }

  protected resultTableTrackByFn(index: number, tableData: TableData): string {
    return tableData.sectionLabel;
  }

  private handleLanrOptions(): void {
    if (this.currentUser?.snapshot && isDoctor(this.currentUser.snapshot)) {
      this.retrieveLanrOptions();
    } else {
      this.currentUser.first$.subscribe((user) => {
        if (isDoctor(user)) {
          this.retrieveLanrOptions();
        }
      });
    }
  }

  /**
   * retrieve users and build the lanr option for each
   */
  private retrieveLanrOptions(): void {
    this.contextState
      .getOrg$()
      .pipe(
        takeUntilDestroyed(),
        filter((org) => !!org)
      )
      .subscribe((org) => {
        this.userClient
          .list({
            bsnr: org.bsnr,
            userType: [KnownUserType.Doctor, KnownUserType.Kvn_Doctor, KnownUserType.Full_Kvn_Doctor],
            size: 1000,
            sort: 'lastName',
          })
          .pipe(
            map((users) => users._embedded.items),
            map((users: AokUser[]) => {
              return users.map((user) => this.getLanrOptionFromUser(user));
            }),
            tap((lanrOptions: DropdownSchema[]) => {
              this.lanrOptions = lanrOptions;
              this.cd.markForCheck();
            })
          )
          .subscribe();
      });
  }

  /**
   * build lanr dropdown option based on given user
   */
  private getLanrOptionFromUser(user: AokUser): DropdownSchema {
    return { value: this.lanrPipe.transform(user.practitionerResource), label: this.getLanrText(user) };
  }

  /**
   * build label for lanr for user
   */
  private getLanrText(user: AokUser): string {
    return `${this.lanrPipe.transform(user.practitionerResource)} - ${this.fullNamePipe.transform(
      user,
      'TT FF LL',
      user.practitionerResource
    )}`;
  }

  private subscribeToPractitioner(): void {
    this.contextState
      .getPractitioner$()
      .pipe(
        takeUntilDestroyed(),
        filter((user) => !!user)
      )
      .subscribe((user) => {
        const lanr = this.lanrPipe.transform(user.practitionerResource);
        this.lanrControl.setValue(lanr);
      });
  }

  private handleBillingSheetPreference(): void {
    this.client.getBillingSheetPreference().subscribe((result) => {
      this.prePrinted = result === AokBillingSheetTypeEnum.PrePrinted;
      this.radioSwitchForm.setValue(result);
      this.matomoService.setMatomoPrintingSheetVariable(result);
      this.cd.markForCheck();
    });
  }

  private handleRadioSwitchChanges(): void {
    this.radioSwitchForm.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.prePrinted = value !== AokBillingSheetTypeEnum.FullPrint;
      this.matomoService.setMatomoPrintingSheetVariable(value);
    });
  }

  private resetResultBannerProps(): void {
    this.status = null;
    this.resultBannerHeadline = null;
    this.resultBannerHint = null;
    this.cd.markForCheck();
  }

  private prepareResultTableData(): void {
    const { invoiceDate, insuranceStatus, ...res } = this.response;
    this.invoiceDate = invoiceDate;
    this.patientId = res.id;
    this.resultTableData = [
      {
        sectionLabel: ServicePagesLabels.BILLING_SHEET_SECTION,
        table: this.getBillingSheetTable(),
      },
      {
        sectionLabel: ServicePagesLabels.PATIENT_DATA_SECTION,
        table: getPersonalResultTable(this.response),
      },
      {
        sectionLabel: ServicePagesLabels.INSURANCE_DATA_SECTION,
        table: getInsuranceDataTable(this.response),
      },
    ];
    this.setStatusAndBannerProperties(insuranceStatus);
    this.cd.markForCheck();
  }

  private getBillingSheetTable(): TableTypeRow[] {
    return [
      {
        label: 'Behandlungsdatum',
        value: new DatePipe('de').transform(this.invoiceDate, 'dd.MM.yyyy'),
      },
    ];
  }

  private setStatusAndBannerProperties(insuranceStatus: AokInsuranceStatus): void {
    const memberStatus = new AokInsuranceStatusPipe();
    this.status = memberStatus.transform(insuranceStatus);
    const isStatusUnclear = this.status === 'unklar';
    this.resultBannerHeadline = isStatusUnclear ? this.insuranceBannerFail : this.insuranceBannerSuccess;
    this.resultBannerBackground = isStatusUnclear ? 'aok-bg-sand' : 'aok-bg-pastel-green';
    if (!isStatusUnclear) {
      this.resultBannerHint = this.insuranceBannerHint;
      this.lanrControl.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((lanr) => this.lanrControl.valid && this.handleLanrControlValid(lanr));

      this.lanrControl.setValue(this.contextState.practitionerLanr9);
    }
    this.cd.markForCheck();
  }

  private handleLanrControlValid(lanr: string): void {
    this.specialization = getSpecializationDescription(this.lanrControl.value, this.specializationOptions);
    this.loadBillingSheetPreview(this.response.id, this.contextState.bsnr, this.invoiceDate, lanr);
    this.cd.markForCheck();
  }

  /**
   * Loads the billing sheet preview for a given (patient, business ID, invoice date, and LANR).
   */
  private loadBillingSheetPreview(patientId: string, bsnr: string, invoiceDate: string, lanr: string): void {
    this.showErrorMsg = false;
    this.previewLoaded = false;
    this.cd.markForCheck();
    this.client
      .getBillingSheetPreview(String(patientId), bsnr, lanr, invoiceDate)
      .pipe(
        catchError(() => {
          this.showErrorMsg = true;
          scrollToTop();
          return EMPTY;
        })
      )
      .subscribe((blob) => {
        this.billingSheetPreview = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        this.previewLoaded = true;
        this.cd.markForCheck();
      });
  }
}
